import axios from "axios";
import React, { useEffect, useState } from "react";
import { setBaseUrl } from "../../config";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader";
import InvoiceTable from "./NewLease/InvoiceTable";

import Select from "react-select";
import Document from "../viewer/Document";
import ImageViewer from "../viewer/ImageViewer";
import Image from "../viewer/Image";
import deleteDocument from "../../apis/files/deleteDocument";
import AddDocument from "../uploads/AddDocument";
import upload_document from "../../utils/DocumentUploader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UpdateLease({ tabNo, setTabNo, leaseId, setLeaseId }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [leasType, setLeaseType] = useState(false);
  const [allAssets, setAllAssets] = useState([]);
  const [allOperators, setAllOperators] = useState([]);
  const [operatorsById, setOperatorsById] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [leaseInfo, setLeaseInfo] = useState({
    lease_type: "",
    customer_po_no: "",
    currency: "",
    rental_start_date: "",
    rental_end_date: "",
    rental_extended_date: "",
    operator: "",
    customer: "",
    // lease_status: "",
    contract_value: "",
    transportation_charge: "",
    normal_amount: "",
    overtime_amount: "",
    reimbursements: "",
    total_claimable_amount: "",
    id: id,
    asset_id: "",
    sale_person: "",
    closed_date: "",
  });
  //set invoice info
  //define the object schema
  const invoiceObjectSchema = {
    invoice_name: "",
    invoice_date: "",
    invoice_id: "",
    operator_name: "",
    document: "",
  };
  const [invoiceInfo, setInvoiceInfo] = useState([]);
  const addInvoiceField = () => {
    let modifiedInvoice = [...invoiceInfo, invoiceObjectSchema];
    setInvoiceInfo(modifiedInvoice);
  };

  //target the photos input element by using useRef hook
  const photoInputRef = React.useRef(null);
  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  const [showImage, setShowImage] = useState(null);

  //target the document input element by using useRef hook
  const documentInputRef = React.useRef(null);
  const [uploadedDocument, setUploadedDocument] = useState(null);
  const [showDocument, setShowDocument] = useState(null);

  //show existing images
  const [alreadyUploadedImages, setAlreadyUploadedImages] = useState([]);
  //show existing documents
  const [alreadyUploadedDocuments, setAlreadyUploadedDocuments] = useState([]);

  const [updateLeaseData, setUpdateLeaseData] = useState({
    id: id,
  })



  // const ToggleSwitch = () => {
  //   const [isChecked, setIsChecked] = useState(false);

  //   const handleToggle = () => {
  //     setIsChecked(!isChecked);
  //   };

  const [isChecked, setIsChecked] = useState(false);
  const handleToggle = () => {
    setIsChecked(true);
  };

  //get lease details by Id
  const get_specific_details = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/lease/get_data_by_id`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
          id: id,
        },
      });
      // console.log(data);
      if (data.lease_type === "wet_lease") {
        setLeaseType(true);
      }
      setLeaseInfo(data);
      if (data.attachments.length > 0) {
        let existingAttachments = data.attachments.map((item) => item.doc_uri);
        setAlreadyUploadedDocuments(data.attachments);
      }
      if (data.photos.length > 0) {
        let existingPhotos = data.photos.map((item) => item.image_uri);
        setAlreadyUploadedImages(existingPhotos);
      }
      // setLeaseInfo({...leaseInfo, asset_id: data[0]?.asset_id})
    } catch (error) {
      // console.error(error);
    }
  };

  const getAllAssets = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/asset/get_all`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
      setAllAssets(data);
    } catch (error) {
      // console.error(error);
    }
  };

  const getInvoices = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/lease/get-invoice`, {
        headers: {
          "x-access-tokens": sessionStorage.getItem("token"),
          "lease-id": id,
        },
      });
      // console.log(data);
      setInvoiceInfo(data);
    } catch (error) {
      // console.error(error);
    }
  };

  const getOperatorsById = async () => {
    try {
      const { data } = await axios.get(
        `${setBaseUrl}/lease/get-operator-by-lease-id`,
        {
          headers: {
            "x-access-tokens": sessionStorage.getItem("token"),
            "lease-id": id,
          },
        }
      );
      // console.log(data);
      let operatorOptions = data.map((item) => {
        return {
          value: item.id,
          label: item.operator_name,
        };
      });
      // console.log(operatorOptions);
      setOperatorsById(operatorOptions);
      return data;
    } catch (error) {
      // console.error(error);
    }
  };

  const getAllOperators = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/operator/get_all`, {
        headers: {
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });
      // console.log(data);
      const idSpecificOperators = await getOperatorsById();
      // console.log(idSpecificOperators);
      let filteredOperatorList = data.filter((item) => {
        for (let i = 0; i < idSpecificOperators.length; i++) {
          // console.log(item.id);
          // console.log(idSpecificOperators[i].id);
          if (item.id === idSpecificOperators[i].operator_id) {
            // console.log("mile gache");
            return;
          }
        }
        // console.log("maleni");
        return item;
      });
      // console.log(filteredOperatorList);
      let operatorOptions = filteredOperatorList.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setAllOperators(operatorOptions);
    } catch (error) {
      // console.error(error);
    }
  };



  useEffect(() => {
    get_specific_details();
    getAllAssets();
    getInvoices();
    getOperatorsById();
    getAllOperators();
  }, []);


  //set the photo to send to the server and url to show the image
  const handlePhotoChange = (e) => {
    setUploadedPhoto(e.target.files);
    let photoFiles = e.target.files;
    let photoArray = [];
    for (const key in photoFiles) {
      if (photoFiles.hasOwnProperty(key)) {
        photoArray.push(photoFiles[key]);
      }
    }
    setShowImage(photoArray);
  };
  //invoke the image upload input on button click
  const handlePhotoInput = (e) => {
    photoInputRef.current.click();
  };

  //set the document to send to the server
  const handleDocumentChange = (e) => {
    setUploadedDocument(e.target.files);
    let docFiles = e.target.files;
    let docArray = [];
    for (const key in docFiles) {
      if (docFiles.hasOwnProperty(key)) {
        docArray.push(docFiles[key]);
      }
    }
    setShowDocument(docArray);
  };

  //invoke the document upload input on button click
  const handleDocumentUploadInput = (e) => {
    documentInputRef.current.click();
  };

  const handleLeaseTypeSelect = (e) => {
    setLeaseInfo({ ...leaseInfo, lease_type: e.target.value });
    setUpdateLeaseData({...updateLeaseData, "lease_type": e.target.value})
    if (e.target.value == "wet_lease") setLeaseType(true);
    else if (e.target.value == "customer_certified") setLeaseType(true);
    else setLeaseType(false);
  };

  // - - - - - - - - - - - functtions for data updates starts- - - - - - - - - - - -

  //upload image
  const upload_image = async (lease_id) => {
    const formData = new FormData();
    formData.append("lease_id", lease_id);
    formData.append("types", "lease");
    if (uploadedPhoto) {
      for (const key in uploadedPhoto) {
        if (uploadedPhoto.hasOwnProperty(key))
          formData.append("photo", uploadedPhoto[key]);
      }
    }
    try {
      // setLoader(true)
      const { data } = await axios.post(
        `${setBaseUrl}/lease/upload_photo`,
        formData,
        {
          headers: {
            "x-access-tokens": sessionStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {
      // console.error(error);
    } finally {
      // setLoader(false)
    }
  };

  //update invoice
  const updateInvoices = async () => {
    for (let i = 0; i < invoiceInfo.length; i++) {
      // console.log(leaseId);
      // let formData = new FormData();
      // formData.append("invoice_no",invoiceInfo[i].invoice_id)
      // formData.append("invoice_name", invoiceInfo[i].invoice_name)
      // formData.append("invoice_date", invoiceInfo[i].invoice_date)
      // formData.append("operator_name", invoiceInfo[i].operator_name)
      // formData.append("document", invoiceInfo[i].document)
      // formData.append("lease_id", leaseId)

      try {
        const { data } = await axios.put(
          `${setBaseUrl}/lease/update-invoice`,
          invoiceInfo[i],
          {
            headers: {
              // "Content-Type": "application/json",
              "x-access-tokens": sessionStorage.getItem("token"),
            },
          }
        );
      } catch (error) {
        alert(error.message);
        return false;
      }
    }
    return true;
  };

  const assignOperators = async () => {
    if (selectedOptions.length == 0) return true;
    for (let i = 0; i < selectedOptions.length; i++) {
      try {
        const { data } = await axios.post(
          `${setBaseUrl}/lease/assign-operator`,
          {
            lease_id: id,
            operator_name: selectedOptions[i].label,
            operator_id: selectedOptions[i].value,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-access-tokens": sessionStorage.getItem("token"),
            },
          }
        );
      } catch (error) {
        alert(error.message);
        return false;
      }
    }
    return true;
  };

  const deleteOperators = async (mapping_id) => {
    try {
      const { data } = await axios.delete(
        `${setBaseUrl}/lease/delete-mapping-operator-by-id`,
        {
          headers: {
            "x-access-tokens": sessionStorage.getItem("token"),
            "mapping-id": mapping_id,
          },
        }
      );
      getOperatorsById();
    } catch (error) {}
  };

  // const handleUpdate = async () => {
  //   console.log(leaseInfo);
  //   try {
  //     setLoader(true);
  //     const { data } = await axios.put(
  //       `${setBaseUrl}/lease/update`,
  //       leaseInfo,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "x-access-tokens": sessionStorage.getItem("token"),
  //         },
  //       }
  //     );
  //     if (uploadedDocument) {
  //       let ifDocumentUploaded = await upload_document(
  //         id,
  //         "lease",
  //         uploadedDocument
  //       );
  //       if (!ifDocumentUploaded) {
  //         return;
  //       }
  //     }
  //     if (uploadedPhoto) await upload_image(id);
  //     if (setLeaseType) {
  //       await updateInvoices();
  //       await assignOperators();
  //     }

  //     alert("Data updated successfully");
  //     navigate("/lease/leaseDetails/" + id);
  //   } catch (error) {
  //     // console.error(error);
  //   } finally {
  //     setLoader(false);
  //   }
  // };
  const handleUpdate = async () => {
    // console.log(leaseInfo);
    console.log(updateLeaseData)
    try {
      setLoader(true); // Set loading state to true
  
      const { data } = await axios.put(
        `${setBaseUrl}/lease/update`,
        updateLeaseData,
        {
          headers: {
            "Content-Type": "application/json",
            "x-access-tokens": sessionStorage.getItem("token"),
          },
        }
      );
  
      // Handle document upload
      if (uploadedDocument) {
        let ifDocumentUploaded = await upload_document(id, "lease", uploadedDocument);
        if (!ifDocumentUploaded) {
          return;
        }
      }
  
      // Handle photo upload
      if (uploadedPhoto) await upload_image(id);
  
      // Update lease type and assign operators if necessary
      if (setLeaseType) {
        await updateInvoices();
        await assignOperators();
      }
  
      // Show success toast
      toast.success("Data updated successfully!");
  
      // Navigate to lease details page
      navigate("/lease/leaseDetails/" + id);
    } catch (error) {
      // Show error toast
      toast.error("An error occurred while updating the lease. Please try again.");
      console.error(error);
    } finally {
      setLoader(false); // Set loading state to false when the API call finishes
    }
  };
  const [openDelete, setOpenDelete] = useState(false);

  const handleDelete = async () => {
    // console.log("Deleting lease with ID:", id);
    try {
      // Ensure id is passed correctly

      const res = await axios.delete(`${setBaseUrl}/lease/delete`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
        data: { id }, // Only stringify the ID
      });

      if (res.status === 200) {
        alert("Deleted Successfully");
      } else {
        throw new Error(`Error: ${res.status} - ${res.statusText}`);
      }
    } catch (error) {
      console.error("Error deleting lease:", error);
      alert("Deletion failed!!");
      setOpenDelete(false);
    }
  };

  if (loader) {
    return <Loader />;
  } else {
    return (
      <>
        <div id="update-lease" className=" ">
          <div className="flex  justify-start items-center">
            <button
              className="bg-white text-[10px] h-7 w-[96px] shadow-md"
              onClick={() => navigate("/lease/leaseDetails/" + id)}
            >
              Back
            </button>
            <p className="inline py-5 pl-5 text-xs font-medium text-slate-700 dark:text-slate-200">
              Update Lease
            </p>
          </div>

          <div className="flex animSlideup relative">
            <div className="intro-y box basis-1/2 bg-white dark:bg-slate-900 rounded-lg mx-2 border">
              <div className="p-5 text-xs">
                <div className="mt-0">
                  <label>Lease Type</label>
                  <select
                    className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    onClick={handleLeaseTypeSelect}
                  >
                    <option
                      value="dry_lease"
                      selected={
                        leaseInfo.lease_type === "dry_lease" ? true : false
                      }
                    >
                      Dry Lease
                    </option>
                    <option
                      value="wet_lease"
                      selected={
                        leaseInfo.lease_type === "wet_lease" ? true : false
                      }
                    >
                      Wet Lease
                    </option>
                    <option
                      value="customer_certified"
                      selected={
                        leaseInfo.lease_type === "customer_certified"
                          ? true
                          : false
                      }
                    >
                      Customer Certified
                    </option>
                  </select>
                </div>
                <div className="mt-4">
                  <label className="">Asset</label>
                  <select
                    className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    onChange={(e) =>
                      {setLeaseInfo({ ...leaseInfo, asset_id: e.target.value })
                    setUpdateLeaseData({
                      ...updateLeaseData, "asset_id": e.target.value
                    })
                    }
                    }
                  >
                    {allAssets.map((item) => (
                      <option
                        value={item.id}
                        selected={item.id === leaseInfo.asset_id ? true : false}
                      >
                        {item?.asset_no}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mt-4">
                  <label className="">Customer PO No</label>
                  <input
                    value={leaseInfo.customer_po_no}
                    onChange={(e) =>
                      {setLeaseInfo({
                        ...leaseInfo,
                        customer_po_no: e.target.value,
                      })
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        'customer_po_no': e.target.value,
                      })
                    }
                    }
                    type="text"
                    placeholder="eg: RE673212"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                <div className="mt-4">
                  <label className="">Currency</label>
                  <input
                    type="text"
                    value={leaseInfo.currency}
                    onChange={(e) =>
                      {setLeaseInfo({ ...leaseInfo, currency: e.target.value })
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        'currency': e.target.value,
                      })}
                    
                    }
                    placeholder="Currency (eg: $)"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                <div className="mt-4">
                  <label className="">Rental Start Date</label>
                  <input
                    value={leaseInfo.rental_start_date}
                    onChange={(e) =>
                      {setLeaseInfo({
                        ...leaseInfo,
                        rental_start_date: e.target.value,
                      })
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        'rental_start_date': e.target.value,
                      })}
                    
                    }
                    type="date"
                    placeholder="07-03-2021"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                <div className="mt-4">
                  <label className="">Rental End Date</label>
                  <input
                    value={leaseInfo.rental_end_date}
                    onChange={(e) =>
                      {setLeaseInfo({
                        ...leaseInfo,
                        rental_end_date: e.target.value,
                      })
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        'rental_end_date': e.target.value,
                      })
                    }
                    }
                    type="date"
                    placeholder="07-03-2021"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                <div className="mt-4">
                  <label className="">Extended Rental End Date</label>
                  <input
                    value={leaseInfo?.rental_extended_date}
                    onChange={(e) =>
                      {setLeaseInfo({
                        ...leaseInfo,
                        rental_extended_date: e.target.value,
                      })
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        'rental_extended_date': e.target.value,
                      })}
                    }
                    type="date"
                    placeholder="07-03-2021"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                <div className="flex flex-col mt-4">
                  <label className="">Sales Person</label>
                  <select
                    className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    onChange={(e) =>
                      {setLeaseInfo({
                        ...leaseInfo,
                        sale_person: e.target.value,
                      })
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        'sale_person': e.target.value,
                      })
                    }
                    }
                  >
                    <option value="" selected={leaseInfo.sale_person == ""}>
                      {""}
                    </option>
                    <option
                      value="Raja Ghosh"
                      selected={leaseInfo.sale_person == "Raja Ghosh"}
                    >
                      Raja Ghosh
                    </option>
                    <option
                      value="Arjun Singh"
                      selected={leaseInfo.sale_person == "Arjun Singh"}
                    >
                      Arjun Singh
                    </option>
                    <option
                      value="Prashant R"
                      selected={leaseInfo.sale_person == "Prashant R"}
                    >
                      Prashant R
                    </option>
                    <option
                      value="Ajay K"
                      selected={leaseInfo.sale_person == "Ajay K"}
                    >
                      Ajay K
                    </option>
                    <option
                      value="Amit Mishra"
                      selected={leaseInfo.sale_person == "Amit Mishra"}
                    >
                      Amit Mishra
                    </option>
                    <option
                      value="Sunil K"
                      selected={leaseInfo.sale_person == "Sunil K"}
                    >
                      Sunil K
                    </option>
                    <option
                      value="Nimit Mori"
                      selected={leaseInfo.sale_person == "Nimit Mori"}
                    >
                      Nimit Mori
                    </option>
                    <option
                      value="Raja P"
                      selected={leaseInfo.sale_person == "Raja P"}
                    >
                      Raja P
                    </option>
                    <option
                      value="Krishna Patel"
                      selected={leaseInfo.sale_person == "Krishna Patel"}
                    >
                      Krishna Patel
                    </option>
                  </select>
                </div>
                <div className="mt-4">
                  <label className="">Number of Operators</label>
                  <input
                    value={leaseInfo?.operator}
                    onChange={(e) =>
                      {setLeaseInfo({
                        ...leaseInfo,
                        operator: e.target.value,
                      })
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        'operator': e.target.value,
                      })}
                    }
                    type="number"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>

                <div>
                  {" "}
                  <div className="mt-4">
                    <label className="">Select Operators</label>
                    <Select
                      defaultValue={[...operatorsById]}
                      isMulti
                      onChange={(e) => setSelectedOptions(e)}
                      name="colors"
                      options={allOperators}
                      className="basic-multi-select"
                      isOptionDisabled={() =>
                        selectedOptions.length >= leaseInfo?.operator
                      }
                      classNamePrefix="select"
                    />
                  </div>
                  <div className="p-2">
                    <div className="text-xs font-bold"> Assigned Operators</div>
                    {operatorsById.map((item) => (
                      <div className="w-full flex justify-between p-2">
                        <p className="font-[400]">{item.label}</p>
                        <button
                          className="text-white bg-red-1  buttons"
                          onClick={() => deleteOperators(item.value)}
                        >
                          delete
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="intro-y box basis-1/2 bg-white dark:bg-slate-900 rounded-lg mx-2 border">
              <div className="p-5 text-xs">
                <div className="mt-0">
                  <label>Customer</label>
                  <input
                    value={leaseInfo.customer}
                    onChange={(e) =>
                      {setLeaseInfo({ ...leaseInfo, customer: e.target.value })
                      setUpdateLeaseData({ ...updateLeaseData, 'customer': e.target.value })
                    }
                    }
                    type="text"
                    placeholder="eg: John Doe"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                <div className="flex gap-6 mt-4">
                 
                  <div className="flex-grow-[1]">
                    <label for="rso">Status:</label>
                    <input
                      type="text"
                      value={leaseInfo.lease_status === "inactive" ? "Free" : leaseInfo.lease_status}
                      className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    />
                  </div>
               
                  <div className="flex-grow-1 text-xs">
                    <label htmlFor="toggle" className="block font-semibold">
                      Close Contract Date:
                    </label>
                    <input
                      type="date"
                      name="closed_date"
                      value={leaseInfo.closed_date || ""}
                      onChange={(e) =>
                        {setLeaseInfo({
                          ...leaseInfo,
                          closed_date: e.target.value,
                        })
                        setUpdateLeaseData({
                          ...updateLeaseData,
                          'closed_date': e.target.value,
                        })}
                      
                      }
                      className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    />
                  </div>
                </div>

                <div className="mt-4">
                  <div className="flex-grow-[1]">
                    <label for="rso">RSO ID:</label>
                    <input
                      type="text"
                      name="rso"
                      value={leaseInfo.odoo_order_id}
                      onChange={(e) =>
                        {setLeaseInfo({
                          ...leaseInfo,
                          odoo_order_id: e.target.value,
                        })
                        setUpdateLeaseData({
                          ...updateLeaseData,
                          'odoo_order_id': e.target.value,
                        })}
                      }
                      className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                    />
                  </div>
                  <label className="">Contract Value</label>
                  <input
                    value={leaseInfo.contract_value}
                    onChange={(e) =>
                      {setLeaseInfo({
                        ...leaseInfo,
                        contract_value: e.target.value,
                      })
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        'contract_value': e.target.value,
                      })}
                    }
                    type="number"
                    placeholder="0"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                <div className="mt-4">
                  <label className="">Transportation Charges</label>
                  <input
                    value={leaseInfo.transportation_charge}
                    onChange={(e) =>
                      {setLeaseInfo({
                        ...leaseInfo,
                        transportation_charge: e.target.value,
                      })
                      setUpdateLeaseData({
                        ...updateLeaseData,
                        'transportation_charge': e.target.value,
                      })}
                    }
                    type="number"
                    placeholder="0"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                <div className="mt-4">
                  <label className="">Normal Amount</label>
                  <input
                    value={leaseInfo.normal_amount}
                    onChange={(e) =>
                      {setLeaseInfo({
                        ...leaseInfo,
                        normal_amount: e.target.value,
                      })
                      // setUpdateLeaseData({
                      //   ...updateLeaseData,
                      //   'normal_amount': e.target.value,
                      // })
                    }
                    }
                    type="number"
                    placeholder="0"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                <div className="mt-4">
                  <label className="">Overtime Amount</label>
                  <input
                    value={leaseInfo.overtime_amount}
                    onChange={(e) =>
                      setLeaseInfo({
                        ...leaseInfo,
                        overtime_amount: e.target.value,
                      })
                    }
                    type="number"
                    placeholder="0"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                <div className="mt-4">
                  <label className="">Reimbursements</label>
                  <input
                    value={leaseInfo.reimbursements}
                    onChange={(e) =>
                      setLeaseInfo({
                        ...leaseInfo,
                        reimbursements: e.target.value,
                      })
                    }
                    type="number"
                    placeholder="0"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
                <div className="mt-4">
                  <label className="">Total Claimable Amount</label>
                  <input
                    value={leaseInfo.total_claimable_amount}
                    onChange={(e) =>
                      setLeaseInfo({
                        ...leaseInfo,
                        total_claimable_amount: e.target.value,
                      })
                    }
                    type="number"
                    placeholder="0"
                    className="bg-light-10 w-full mt-2 p-2 text-dark-6 border-none focus:ring-0 focus:border-none dark:bg-slate-900 font-medium rounded-md text-xs shadow-none"
                  />
                </div>
              </div>
            </div>
          </div>
          {leasType && (
            <div className="basis-full bg-white dark:bg-slate-900 rounded-lg mx-2 mt-5 shadow-md hover:shadow-xl">
              <p className="p-4 font-medium text-xs">Invoice Details</p>
              <div className="mt-4 p-4">
                {invoiceInfo.map((item, index) => (
                  <InvoiceTable
                    index={index}
                    setInvoiceData={setInvoiceInfo}
                    allInvoiceData={invoiceInfo}
                  />
                ))}
                {/* Add invoice button */}
             
              </div>
            </div>
          )}
          <AddDocument
            title="Lease Documents"
            uploadedDocument={uploadedDocument}
            setUploadedDocument={setUploadedDocument}
            alreadyUploadedDocuments={alreadyUploadedDocuments}
            setAlreadyUploadedDocuments={setAlreadyUploadedDocuments}
            forSection="lease"
          />
          <div className="flex animSlideup relative">
            <div className="basis-full bg-white dark:bg-slate-900 rounded-lg mx-2 mt-5 border justify-between">
              <div className="flex p-5 border-b border-gray-200 dark:border-dark-5">
                <h2 className="font-medium text-xs mr-auto ">Lease Photo</h2>
                <button
                  onClick={handlePhotoInput}
                  className="button border rounded-lg py-2 px-2 font-medium text-xs text-right text-gray-800 dark:border-gray-400 dark:text-gray-300 hidden sm:flex"
                >
                  <input
                    ref={photoInputRef}
                    type="file"
                    multiple
                    accept="image/jpeg, image/png"
                    className="hidden"
                    wfd-id="id57"
                    onChange={handlePhotoChange}
                  />
                  +Add photo
                </button>
              </div>
              <div className="p-5 text-xs">
                {uploadedPhoto && (
                  <div className="flex flex-col">
                    <span className="text-xs">Uploading....</span>
                    {showImage.length > 0 &&
                      showImage.map((img) => <p>{img.name}</p>)}
                  </div>
                )}
              </div>
              {alreadyUploadedImages != null && (
                <div className="p-5 text-xs">
                  <span className="text-xs">Existing</span>
                  {alreadyUploadedImages.map((img) => (
                    // <p>{img.substring(img.length - 17)}</p>
                    <Image image_uri={img} />
                  ))}
                </div>
              )}
            </div>
          </div>
         
        </div>
     
        <div class="flex mt-5 border bg-white fixed bottom-0 left-0 right-0 pr-6 h-12 items-center justify-end text-xs gap-3 ">
        <button
            className="shadow-md text-white bg-red-1 buttons"
            onClick={() => setOpenDelete(true)}
          >
            Delete 
          </button>
     
          <button
            onClick={handleUpdate}
            className=" shadow-md text-white bg-blue-2  buttons"
          >
            Save
          </button>
        </div>
        {openDelete && (
          <>
            <div className=" fixed z-[9999] left-[35%] top-[20%] w-[400px] flex flex-col justify-center items-center gap-5 h-[300px] bg-white">
              <label htmlFor="" className="text-xs text-light-2">
                Do you really want to delete this lease?
              </label>
              <div className="flex text-xs gap-2">
                <button
                  className="border border-dark-6 text-dark-6 h-7 w-[70px]"
                  onClick={() => setOpenDelete(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-red-600 text-white h-7 w-[70px]"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
            <div
              className="fixed top-0 left-0 bottom-0 right-0 z-0 bg-[#0000000f]"
              onClick={() => setOpenDelete(false)}
            />
          </>
        )}
      </>
    );
  }
}

export default UpdateLease;
