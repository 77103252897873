import React, { useState } from "react";
import MachineForm from "./MachineForm";
import { useGetAsset, useGetOperator } from "../api/newLeaseApi";
import { setBaseUrl } from "../../../../config";

const NewMachine = ({
  machineData,
  setMachineData,
  selectedOperator,
  setSelectedOperator,
 
}) => {
  const { assetArray } = useGetAsset(`${setBaseUrl}/asset/get_all`);
  const { operatorArray } = useGetOperator(`${setBaseUrl}/operator/get_all`);

  const [error, setError] = useState("");

  const removeMachine = (index) => {
    // Remove machine at the specified index
    const updatedMachines = machineData.filter((_, idx) => idx !== index);
    setMachineData(updatedMachines);
  };

  

  return (
    <div>
      {/* <h1>Total Machines: {count}</h1> */}

      {/* Loop through machineData to render forms for each machine */}
      {machineData.length > 0 &&
        machineData?.map((machine, index) => (
          <>
         
          <div key={index} className="border p-5 my-2 flex justify-start gap-10">
            <div className="flex flex-col">
              <MachineForm
                name="Asset No"
                type=""
                label={machine.asset}
                assetArray={assetArray} // Ensure assetArray is defined
                machineData={machineData}
                setMachineData={setMachineData} // Pass it down
                machineIndex={index} // Pass the current index
              />

              {/* Select Number of operators */}
              <MachineForm
                name="Select Number Of Operators"
                type="number"
                keyNameType="select_number_of_operators"
                label={machine?.select_number_of_operators}
                machineData={machineData}
                machineIndex={index}
                setMachineData={setMachineData}
              />
              {/* MachineForm for Operator Name */}

              <MachineForm
                name="Operator Name"
                type="text"
                label={machine.select_operators}
                operatorArray={operatorArray}
                machineIndex={index}
                machineData={machineData}
                setMachineData={setMachineData}
                selectedOperator={selectedOperator}
                setSelectedOperator={setSelectedOperator}
              />

              {/* MachineForm for Rental Start Date */}
              <MachineForm
                name="Rental Start Date"
                type="date"
                keyNameType="rental_start_date"
                label={machine.rental_start_date}
                machineData={machineData}
                machineIndex={index}
                setMachineData={setMachineData}
                error={error}
                setError={setError}
              />

              {/* Rental End Date */}
              <MachineForm
                name="Rental End Date"
                type="date"
                keyNameType="rental_end_date"
                label={machine.rental_end_date}
                machineData={machineData}
                machineIndex={index}
                setMachineData={setMachineData}
                error={error}
                setError={setError}
              />

              {error ? (
                <>
                  {" "}
                  <div className="text-red-500">{error}</div>{" "}
                </>
              ): ""}
              {/* 
              <MachineForm
                name="Total Working Days/month"
                type="text"
                keyNameType="total_working_days"
                label={machine.total_working_days}
                machineData={machineData}
                machineIndex={index}
                setMachineData={setMachineData}
              /> */}
              {/* nominal_hours_per_day */}
              <MachineForm
                name="Nominal Hrs/day"
                type="number"
                keyNameType="nominal_hours_per_day"
                label={machine.nominal_hours_per_day}
                machineData={machineData}
                machineIndex={index}
                setMachineData={setMachineData}
              />
            </div>
            <div className="flex flex-col">
              {/* Total Working Hours in a month */}

              {/* <MachineForm
                name="Total Working Hrs/month"
                type="text"
                keyNameType="total_working_hours_in_month"
                label={machine.total_working_hours_in_month}
                machineData={machineData}
                machineIndex={index}
                setMachineData={setMachineData}
              /> */}
              {/*Contract value*/}
              <MachineForm
                name="Contract Value"
                type="text"
                keyNameType="contract_value"
                label={machine.contract_value}
                machineData={machineData}
                machineIndex={index}
                setMachineData={setMachineData}
              />

              {/* Monthly Rental Amount */}
              <MachineForm
                name="Monthly Rental Amount"
                type="text"
                keyNameType="monthly_rental_amount"
                label={machine.monthly_rental_amount}
                machineData={machineData}
                machineIndex={index}
                setMachineData={setMachineData}
              />
              {/* Normal Rate */}
              <MachineForm
                name="Normal Hourly Rent"
                type="text"
                keyNameType="normal_rate"
                label={machine.normal_rate}
                machineData={machineData}
                machineIndex={index}
                setMachineData={setMachineData}

              />

              {/*Overtime Rate*/}
              <MachineForm
                name="Overtime Rate"
                type="text"
                keyNameType="overtime_rate"
                label={machine.overtime_rate}
                machineData={machineData}
                machineIndex={index}
                setMachineData={setMachineData}

              />

              {/*Expected Revenue Without Overtime*/}
              {/* <MachineForm
                name="Expected Revenue Without Overtime"
                type="text"
                keyNameType="expected_revenue_without_overtime"
                label={machine.expected_revenue_without_overtime}
                machineData={machineData}
                machineIndex={index}
                setMachineData={setMachineData}
              /> */}
              <MachineForm
                name="Normal Amount"
                type="text"
                keyNameType="normal_amount"
                label={machine.normal_amount}
                machineData={machineData}
                machineIndex={index}
                setMachineData={setMachineData}
              />
              <MachineForm
                name="Overtime Amount"
                type="text"
                keyNameType="overtime_amount"
                label={machine.overtime_amount}
                machineData={machineData}
                machineIndex={index}
                setMachineData={setMachineData}
              />
              <MachineForm
                name="Claimable Amount"
                type="text"
                keyNameType="total_claimable_amount"
                label={machine.total_claimable_amount}
                machineData={machineData}
                machineIndex={index}
                setMachineData={setMachineData}
              />
                 <button
              className="border border-red-600 text-red-600 rounded-[2px] shadow-md w-[65px] px-2 py-1 mt-2"
              onClick={() => removeMachine(index)}
            >
              Remove
            </button>
            </div>
          </div>
          </>
        ))}
    </div>
  );
};

export default NewMachine;
