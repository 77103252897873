import React from 'react';
import { Tooltip } from '@mui/material';
import { capitalizeFirstLetter } from './FormatDate';
import Loading from '../../../Loading';

const AssetHeader = ({ assetDetails, loading }) => {
  return (
    <>
      <div className="items-center overflow-hidden text-justify text-xs w-full gap-5 mt-2 rounded pb-1 flex justify-between max-md:flex-col">
        {/* Frame 1 */}
        <div className="bg-white border overflow-hidden py-0 px-3 w-full lg:w-[200px] shadow-md text-[10px] h-[100px] rounded-[6px]">
          <div className="py-2">
            <span className="text-gray-500">Machine No.</span>
            <Tooltip title={`Machine No: ${assetDetails?.asset_no || 'N/A'}`} arrow>
              <span className="block font-semibold text-xs overflow-hidden">
                {assetDetails?.asset_no} 
              </span>
            </Tooltip>
          </div>
          <div className="py-2 ">
            <span className="text-gray-500">Make</span>
            <Tooltip title={`Make: ${assetDetails?.make || 'N/A'}`} arrow>
              <span className="block font-semibold text-xs">
                {assetDetails?.make} 
              </span>
            </Tooltip>
          </div>
        </div>

        {/* Frame 2 */}
        <div className="bg-white overflow-hidden b0order py-0 px-3 w-full lg:w-[200px] shadow-md text-[10px] h-[100px] rounded-[6px]">
          <div className="py-2">
            <span className="text-gray-500">Model No.</span>
            <Tooltip title={`Model No: ${assetDetails?.model || 'N/A'}`} arrow>
              <span className="block font-semibold text-xs">{assetDetails?.model}</span>
            </Tooltip>
          </div>
          <div className="py-2">
            <span className="text-gray-500">Serial No.</span>
            <Tooltip title={`Serial No: ${assetDetails?.serial_no || 'N/A'}`} arrow>
              <span className="block font-semibold  text-xs">{assetDetails?.serial_no}</span>
            </Tooltip>
          </div>
        </div>

        {/* Frame 3 */}
        <div className="bg-white border overflow-hidden py-0 px-3 w-full lg:w-[200px] shadow-md text-[10px] h-[100px] rounded-[6px]">
          <div className="py-2">
            <span className="text-gray-500">Category</span>
            <Tooltip title={`Category: ${assetDetails?.category || 'N/A'}`} arrow>
              <span className="block font-semibold text-xs">{assetDetails?.category}</span>
            </Tooltip>
          </div>
          <div className="py-2">
            <span className="text-gray-500">Year of Manufacturing</span>
            <Tooltip title={`Year of Manufacturing: ${assetDetails?.yom || 'N/A'}`} arrow>
              <span className="block font-semibold text-xs">{assetDetails?.yom}</span>
            </Tooltip>
          </div>
        </div>

        {/* Frame 4 */}
        <div className="bg-white border overflow-hidden py-0 px-3 w-full lg:w-[200px] shadow-md text-[10px] h-[100px] rounded-[6px]">
          <div className="py-2">
            <span className="text-gray-500">Lease Status</span>
            <Tooltip title={`Lease Status: ${capitalizeFirstLetter(assetDetails?.lease_status || 'N/A')}`} arrow>
              <span
                className={`block font-semibold text-xs ${
                  assetDetails?.lease_status === 'inactive'
                    ? 'text-slate-600'
                    : assetDetails?.lease_status === 'active'
                    ? 'text-green-500'
                    : assetDetails?.lease_status === 'expired'
                    ? 'text-red-600'
                    : 'text-blue-600'
                }`}
              >
                {capitalizeFirstLetter(assetDetails?.lease_status)}
              </span>
            </Tooltip>
          </div>
          <div className="py-2">
            <span className="text-gray-500">Capacity</span>
            <Tooltip title={`Capacity: ${assetDetails?.capacity || 'N/A'}kg - ${assetDetails?.hieght_mechine || 'N/A'}ft`} arrow>
              <span className="block font-semibold text-xs">
                {assetDetails?.capacity}kg - {assetDetails?.hieght_mechine}ft
              </span>
            </Tooltip>
          </div>
        </div>

        {/* Frame 5 */}
        <div className="bg-white border overflow-hidden py-0 px-3 w-full lg:w-[200px] shadow-md text-[10px] h-[100px] rounded-[6px]">
          <div className="py-2">
            <span className="text-gray-500">Tracker</span>
            <Tooltip title={`Tracker: ${assetDetails?.device_no?.trim() ? 'Installed' : 'Not Installed'}`} arrow>
              <span
                className={`block font-semibold text-xs ${
                  assetDetails?.device_no?.trim() ? 'text-blue-600' : 'text-gray-500'
                }`}
              >
                {assetDetails?.device_no?.trim() ? 'Installed' : 'Not Installed'}
              </span>
            </Tooltip>
          </div>
          <div className="py-2">
            <span className="text-gray-500">Ratings</span>
            <Tooltip title={`Ratings: ${assetDetails?.rating || '5'}/5`} arrow>
              <span className="block font-bold text-xs text-red-600">
                {assetDetails?.rating ? `${assetDetails?.rating}` : '5'}/5
              </span>
            </Tooltip>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssetHeader;
