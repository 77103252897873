import React from "react";
import { capitalizeFirstLetter, formatDateTime } from "../Tabs/FormatDate";
import * as XLSX from "xlsx";


const fieldNameMapping = {
  description: "Description",
  lease_id: "Lease ID",
  maintenance_id: "Maintenance ID",
  operator_id: "Operator ID",
  action_time: "Action Time",
  act_by: "Performed By",
  action_type: "Action Type",
  asset_id: "Asset Identifier",
  site_location: "Site Location",
  purchased_from: "Purchased From",
  make: "Manufacturer Name",
  model: "Model Number",
  category: "Category",
  device_no: "Device No",
  installation_date: "Installation Date",
  custom_duty_date_of_clearance: "Date of Clearance",
  // Add more mappings as needed
};

const UserLogData = ({ logData }) => {
  // Function to parse fields and display object values in a readable format
  const parseFields = (fields) => {
    try {
      const parsedFields = JSON.parse(fields);
      return Object.entries(parsedFields)
        .filter(([key]) => key !== "id") // Exclude the `id` field
        .map(([key, value]) => ({
          fieldName: fieldNameMapping[key] || key,
          fieldValue: value,
        }));
    } catch (error) {
      console.error("Error parsing fields:", error);
      return [];
    }
  };

  // Function to render field value, handling complex data types like arrays/objects
  const renderFieldValue = (value) => {
    if (typeof value === "object") {
      // If value is an array or object, display it as a list or stringified object
      return (
        <ul className="list-disc ml-4">
          {Array.isArray(value)
            ? value.map((item, idx) => <li key={idx}>{item}</li>)
            : Object.entries(value).map(([key, val], idx) => (
                <li key={idx}>
                  <strong>{key}:</strong> {val}
                </li>
              ))}
        </ul>
      );
    }
    // Default case: Display value as is (string/number)
    return <span>{value}</span>;
  };


  const handleExportExcel = () => {
    const formattedData = logData.map((data) => {
      const fields = parseFields(data.fields_are || "{}");
      const fieldDetails = fields.map(
        (field) => `${field.fieldName}: ${JSON.stringify(field.fieldValue)}`
      );

      return {
        Time: formatDateTime(data.action_time),
        "Act By": capitalizeFirstLetter(data.act_by),
        "Action Type": capitalizeFirstLetter(data.action_type),
        "Fields Changed": fields.map((field) => field.fieldName).join(", "),
        "Updated Data": fieldDetails.join("\n"),
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "User Log Data");
    XLSX.writeFile(workbook, "UserLogData.xlsx");
  };

  return (
    <div className="min-h-[50vh] text-xs py-2">
      <div className="max-w-7xl mx-auto px-2">
        <div className="mt-3 bg-white shadow-md p-5">
        <div className="flex justify-end mb-4">
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              onClick={handleExportExcel}
            >
              Export to Excel
            </button>
          </div>
          {!logData ? (
            <div className="flex justify-center items-center py-10">
              <div className="animate-spin rounded-full h-8 w-8"></div>
            </div>
          ) : (
            <div className="overflow-x-auto text-[10px]">
              <table className="min-w-full border-collapse divide-y">
                <thead className="divide-y sticky top-0 bg-light-4">
                  <tr>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Time
                    </th>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Act By
                    </th>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Action Type
                    </th>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Fields Changed
                    </th>
                    <th className="text-left py-3 px-4 font-bold text-dark-6 border-b">
                      Updated Data
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {logData.length > 0 ? (
                    logData.map((data, index) => {
                      const fields = parseFields(data.fields_are || "{}");
                      return (
                        <tr key={index} className="hover:bg-light-8 transition">
                          <td className="py-3 px-4 text-dark-6 border-b">
                            {formatDateTime(data.action_time)}
                          </td>
                          <td className="py-3 px-4 text-dark-6 border-b">
                            {capitalizeFirstLetter(data.act_by)}
                          </td>
                          <td className="py-3 px-4 text-dark-6 border-b">
                            {capitalizeFirstLetter(data.action_type)}
                          </td>
                          <td className="py-3 px-4 text-dark-6 border-b">
                            <ul className="list-disc ml-4">
                              {fields.map((field, i) => (
                                <li key={i}>{field.fieldName}</li>
                              ))}
                            </ul>
                          </td>
                          <td className="py-3 px-4 text-dark-6 border-b">
                            {fields.map((field, i) => (
                              <div key={i}>
                                <strong>{field.fieldName}:</strong>{" "}
                                {renderFieldValue(field.fieldValue)}
                              </div>
                            ))}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="5"
                        className="py-4 px-4 text-center text-dark-6"
                      >
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserLogData;
