import React, { useState } from "react";
import {
  formatDate,
  formatDateTime,
  formatTime,
  formatTimeDuration,
} from "./Tabs/FormatDate";
import { BsBoxArrowInRight } from "react-icons/bs";
import { Button } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { setBaseUrl } from "../../../config";
import { useParams } from "react-router-dom";
import { IoIosAlert } from "react-icons/io";

import * as XLSX from "xlsx";

const TimeLineDuration = ({
  devId,
  setShowMachine,
  handleBottomTab,
  timeLine,
}) => {
  let dates = [];

  for (let i = 0; i < Object.keys(timeLine.working_time).length; i++) {
    dates.push(Object.keys(timeLine.working_time)[i]);
  }

  const header = [
    "Date",
    "Start Time",
    "End Time",
    "Duration",
    "Remarks",
    "Total Instance",
  ];
  const [openDetails, setOpenDetails] = useState(null); // Tracks selected date
  const [currentDate, setCurrentDate] = useState(null);
  const [dateTimeData, setDateTimeData] = useState(null);
  const [openDateTime, setOpenDateTime] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchMachineTimelineDate = async (date) => {
    // console.log(date)
    setLoading(true);
    setOpenDateTime(true);
    try {
      const res = await fetch(`${setBaseUrl}/asset/get-device-timeline-date`, {
        method: "GET",
        headers: {
          "x-access-tokens": sessionStorage.getItem("token"),
          "timeline-date": date,
          "device-id": devId,
        },
      });

      const data = await res.json();
      if (res.status === 200) {
        setDateTimeData(data);
      } else {
        setDateTimeData(null);
      }
      // console.log(data)
      setLoading(false);
      console.log(await res.json(), "res");
    } catch (error) {
      console.error("Error fetching machine timeline data: ", error);
    }
  };

  const handleExportExcel = () => {
    const excelData = dates.reverse().map((date) => ({
      Date: date,
      "Start Time": timeLine.working_time[date]?.start || "N/A",
      "End Time": timeLine.working_time[date]?.end || "N/A",
      Duration: formatTimeDuration(
        timeLine.working_time[date]?.duration || "0:00"
      ),
      "Total Instance": timeLine.instance_time[date]?.length || 0,
      "Verification": timeLine.working_time[date]?.warn ? `Warning` : "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Timeline Data");
    XLSX.writeFile(workbook, "TimelineData.xlsx");
  };

  const handleNextPrev = (direction) => {
    const currentDateObj = new Date(currentDate);
    if (direction === "next") {
      currentDateObj.setDate(currentDateObj.getDate() + 1); // Increment by 1 day
    } else if (direction === "prev") {
      currentDateObj.setDate(currentDateObj.getDate() - 1); // Decrement by 1 day
    }
    const newDate = currentDateObj.toISOString().split("T")[0]; // Format date as YYYY-MM-DD
    setCurrentDate(newDate);
    fetchMachineTimelineDate(newDate); // Fetch data for the new date
  };

  const parseTelData = (telData) => {
    // Convert the tel_data string into a JavaScript object
    const parsedData = JSON.parse(telData);

    // Extracting relevant values
    return {
      SOC: parsedData.SOC,
      ExVolt: parsedData.ExVOLT,
      VCELL: parsedData.VCELL,
    };
  };

  const renderPwrDataPush = (item) => {
    if (item.type_data === "pwr-data-push") {
      const { SOC, ExVolt, VCELL } = parseTelData(item.tel_data);

      return (
        <>
          <div className="flex items-center gap-6">
            <div className="">SOC: {parseInt(SOC)}%</div>
            <div className="">ExVolt: {parseFloat(ExVolt).toFixed(2)}V</div>
            <div className="">VCELL: {parseFloat(VCELL).toFixed(2)}V</div>
          </div>
        </>
      );
    }
    return item.type_data === "vehicle-engine-status" ? item.tel_data : "";
  };



  return (
    <>
      <div className="pb-20 bg-white rounded-[6px] shadow-md">
        <Button
          variant="contained"
          color="primary"
          onClick={handleExportExcel}
          sx={{
            fontSize: "12px",
            color: "white",
            textTransform: "none", // To disable uppercase text transformation
            marginBottom: "10px",
          }}
        >
          Export to Excel
        </Button>
        <div className="">
          {/* Table Container */}
          <div className="overflow-hidden rounded-[4px] border items-left flex flex-col px-2 pt-6 ">
            <h1 className="text-xs font-bold mb-4 text-dark-6 px-4">
              Working Time
            </h1>
            <table className="table-auto w-full text-xs text-left text-dark-6 font-normal divide-y p-4">
              {/* Table Header */}
              <thead className="text-dark-8 border-b">
                <tr>
                  {header.map((col) => (
                    <td key={col} className="px-4 py-2 font-bold text-dark-6">
                      {col}
                    </td>
                  ))}
                </tr>
              </thead>
              {/* Table Body */}
              <tbody className="divide-y">
                {dates.reverse().map((date) => (
                  <React.Fragment key={date}>
                    <tr className="hover:bg-gray-50">
                      <td className="px-4 py-2 flex items-center space-x-2">
                        {date}
                       
                      </td>

                      <td className="px-4 py-2">
                        {timeLine?.working_time[date]?.dispute_time ? (
                          <>
                            {formatTime(timeLine?.working_time[date]?.start)}
                            <br />
                            {`${formatTime(
                              timeLine?.working_time[date]?.dispute_time?.start
                            )} (dispute time)`}
                          </>
                        ) : (
                          <>{timeLine?.working_time[date]?.start ? formatTime(timeLine?.working_time[date]?.start): "0:00"}</>
                        )}
                      </td>

                      <td className="px-4 py-2">
                        {timeLine?.working_time[date]?.dispute_time ? (
                          <>
                            {formatTime(timeLine?.working_time[date]?.end)}
                            <br />
                            {`${formatTime(
                              timeLine?.working_time[date]?.dispute_time?.end
                            )} (dispute time)`}
                          </>
                        ) : (
                          <>{timeLine?.working_time[date]?.end ? formatTime(timeLine?.working_time[date]?.end): "0:00"}</>
                        )}
                      </td>

                      <td className="px-4 py-2">
                        {timeLine?.working_time[date]?.dispute_time ? (
                          <>
                            {formatTimeDuration(
                              timeLine?.working_time[date]?.duration
                            )}
                            <br />
                            {`${formatTimeDuration(
                              timeLine?.working_time[date]?.dispute_time
                                ?.duration
                            )} (dispute duration)`}
                          </>
                        ) : (
                          <>
                            {timeLine?.working_time[date]?.duration
                              ? formatTimeDuration(
                                  timeLine?.working_time[date]?.duration
                                )
                              : "0:00"}
                          </>
                        )}
                      </td>
                      <td className="px-4 py-2 ">
                      {timeLine?.working_time[date]?.warn ? (
                          <>
                            {/* <IoIosAlert
                              onClick={
                                () =>
                                  setOpenWarn(openWarn === date ? null : date) 
                              }
                              className="text-yellow-300 h-4 w-4"
                            /> */}
                            {/* {openWarn === date && ( */}
                              <div className="text-yellow-1  font-extrabold text-xs">
                              Please Check the problem
                              </div>
                            {/* )} */}
                          </>
                        ) : (
                          ""
                        )}
                       
                      </td>
                     
                      <td className="px-4 py-2 flex gap-3 items-center justify-between w-[140px]">
                        <span>
                          {timeLine?.instance_time[date]?.length || 0}
                        </span>

                        <Button
                          onClick={() => setOpenDetails(date)}
                          variant="contained"
                          sx={{
                            fontSize: "12px",
                            color: "white",
                            textTransform: "none", // To disable uppercase text transformation
                          }}
                        >
                          <BsBoxArrowInRight className="h-3 w-3 font-bold mr-2" />
                          Open
                        </Button>
                      </td>

                      
                    </tr>

                    {/* Modal */}
                    {openDetails === date && (
                      <>
                        <div className="fixed z-50 left-[10%] top-[10%] w-[80%] bg-white p-6 rounded-[4px]">
                          {/* Modal Header */}

                          <Button
                            variant="outlined"
                            sx={{
                              color: "#7C7C7C",
                              borderColor: "#7C7C7C",
                              fontSize: 12,
                            }}
                            onClick={() => setOpenDetails(null)} // Close modal
                          >
                            Close
                          </Button>

                          <div className="flex mt-6 items-center justify-between mb-4">
                            <h2 className="text-xs font-semibold text-dark-6">
                              Details for: {date}
                            </h2>
                          </div>
                          {/* Details Table */}
                          <div className="overflow-auto h-[500px] pt-1 hide-scrollbar px-2 ">
                            {/* <table className="w-full text-[10px] text-dark-6">
                              <thead className="border-b">
                                <tr>
                                  <th className="px-4 py-2">Instance No</th>
                                  <th className="px-4 py-2">Start Time</th>
                                  <th className="px-4 py-2">End Time</th>
                                </tr>
                              </thead>
                              <tbody>
                                {timeLine.instance_time[date]?.map((item, i) => (
                                  <tr key={i} className="border-b">
                                    <td className="px-4 py-2">{i + 1}</td>
                                    <td className="px-4 py-2">
                                      {formatTimeDuration(item.start)}
                                    </td>
                                    <td className="px-4 py-2">
                                      {formatTimeDuration(item.end)}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table> */}
                            <TableContainer
                              component={Paper}
                              style={{ maxHeight: 395, overflowY: "auto" }}
                            >
                              <Table
                                size="small"
                                stickyHeader
                                aria-label="timeline table"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Instance No</TableCell>
                                    <TableCell>Start Time</TableCell>
                                    <TableCell>End Time</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {timeLine.instance_time[date]?.map(
                                    (item, i) => (
                                      <TableRow key={i}>
                                        <TableCell>{i + 1}</TableCell>
                                        <TableCell>{item.start}</TableCell>
                                        <TableCell>{item.end}</TableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </div>
                        </div>
                        {/* Overlay */}
                        <div
                          className="bg-[#0000001f] bg-opacity-25 fixed inset-0 z-40"
                          onClick={() => setOpenDetails(null)} // Close modal on overlay click
                        />
                      </>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <input
          type="date"
          value={currentDate || ""}
          className="mx-4 mt-2"
          onChange={(e) => {
            let date = e.target.value;
            setCurrentDate(date);
            fetchMachineTimelineDate(date);
          }}
        />
        {/* Modal */}
        {openDateTime && (
          <>
            <div className="flex items-center gap-2 mt-2 px-4">
              <Button
                variant="outlined"
                sx={{
                  color: "#7C7C7C",
                  borderColor: "#7C7C7C",
                  fontSize: 12,
                }}
                onClick={() => setOpenDateTime(false)}
              >
                Close
              </Button>
              <Button variant="outlined" onClick={() => handleNextPrev("prev")}>
                Prev
              </Button>
              <Button variant="outlined" onClick={() => handleNextPrev("next")}>
                Next
              </Button>
            </div>

            {dateTimeData ? (
              <>
                <div className=" z-50 left-[10%] top-[10%] w-[100%] bg-white p-6 rounded-[4px]">
                  <div className="flex mt-6 items-center justify-between mb-4">
                    <h2 className="text-xs font-semibold text-dark-6">
                      Details for: {dateTimeData.date}
                    </h2>
                  </div>

                  <div className=" pt-1 hide-scrollbar px-6 ">
                    {loading ? (
                      <div className="text-xs px-6">Loading...</div>
                    ) : (
                      <TableContainer component={Paper}>
                        <Table
                          size="small"
                          stickyHeader
                          aria-label="timeline table"
                        >
                          <TableHead>
                            <TableRow>
                              <TableCell>Date</TableCell>
                              <TableCell>Time</TableCell>
                              <TableCell>Data Type</TableCell>
                              <TableCell>Telematrics Data</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dateTimeData?.map((item, i) => (
                              <TableRow key={i}>
                                <TableCell>{formatDate(item.date)}</TableCell>
                                <TableCell>
                                  {item.time_now.split(" ")[4]}
                                </TableCell>
                                <TableCell>
                                  {item.type_data === "pwr-data-push"
                                    ? "Power Data"
                                    : "Vehicle Engine"}
                                </TableCell>
                                <TableCell>{renderPwrDataPush(item)}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="text-xs px-6">
                <br />
                {loading ? (
                  "Loading..."
                ) : (
                  <> No data available for {currentDate}</>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default TimeLineDuration;
