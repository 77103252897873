// import React from "react";
// import { currency, formatDate } from "./FormatDate";


// const AssetCommercial = ({ commercialDetails }) => {
//   const TotalLandedCost = parseFloat(
//     commercialDetails[0]?.total_landed_cost
//   ).toFixed(2);

//   const TotalLandedCostWithGST = parseFloat(
//     commercialDetails[0]?.total_landed_cost_with_gst
//   ).toFixed(2);

//   const BOENum = commercialDetails[0]?.boe_no;

//   const insurance = commercialDetails[0]?.insurance_renewal
//     ? formatDate(parseInt(commercialDetails[0]?.insurance_renewal))
//     : "--";

//   const CommercialData = [
//     { title: "Total Landed Cost", label: `₹${currency(TotalLandedCost)}` || "N/A"},
//     { title: "Ex-Works Price", label: `$${currency(commercialDetails[0]?.exworks_price)}`|| "N/A" },
//     { title: "CIF Charges", label: `$${currency(commercialDetails[0]?.cif_charges)}` || "N/A"},
//     { title: "Total Cost (Ex-Works + CIF)", label: `$${currency(commercialDetails[0]?.total_cost)}` || "N/A"},
//     { title: "Port of Clearance", label: commercialDetails[0]?.port_of_clearance || "N/A" },
//     { title: "Date of Clearance", label: '--' },  // Example with no data
//     { title: "Purchase Order No", label: commercialDetails[0]?.purchase_order_no || "N/A" },
//     { title: "Purchase Order Date", label: formatDate(commercialDetails[0]?.purchase_order_date)|| "N/A" },
//     { title: "Insurance Renewal", label: insurance || "N/A"},
//     { title: "Exchange Rate (at Remittance)", label: `₹${commercialDetails[0]?.exchange_rate_rem}`|| "N/A" },
//     { title: "Amount of Remitted OEM", label: `₹${currency(commercialDetails[0]?.amount_rem_to_oem)}`|| "N/A" },
//     { title: "Date of Remittance to OEM", label: formatDate(commercialDetails[0]?.date_of_rem_to_oem) || "N/A"},
//     { title: "BOE No", label: BOENum|| "N/A" },
//     { title: "Exchange Rate (at BOE)", label: `₹${commercialDetails[0]?.exrate_boe}`|| "N/A" },
//     { title: "Custom Duty Value", label: `₹${currency(commercialDetails[0]?.custom_duty_value)}`|| "N/A" },
//     { title: "Clearing Charges", label: `₹${currency(commercialDetails[0]?.clearing_charges)}`|| "N/A" },
//     { title: "CHA Charge", label: `₹${currency(commercialDetails[0]?.cha_charges)}` || "N/A"},
//     { title: "GST Amount", label: `${commercialDetails[0]?.gst_amount === null || "nill"  ? 'N/A' :  `₹${currency(commercialDetails[0]?.gst_amount)}`}` },
//     { title: "Total Landed Cost with GST", label: `${TotalLandedCostWithGST=== null || "nill" ? 'N/A' : `₹${currency(TotalLandedCostWithGST)}`}` },
//     { title: "Transportation Charges (INR)", label: commercialDetails[0]?.transportation_charges ? `₹${currency(commercialDetails[0]?.transportation_charges)}` : "N/A" }
//   ];

//   return (
//     <>
    
//     {CommercialData !== null ? <>
//     <div className=" text-xs w-full">
//       <h1 className="text-[#4b5563] font-extrabold mb-4">
//         Asset Commercial Details
//       </h1>
//       <div className="grid grid-cols-4 gap-2 ">
//         {/* First column with first 6 items */}
//         <div className="space-y-0 bg-white  shadow-md rounded-[4px]">
//           {CommercialData?.slice(0, 6).map((item, index) => (
//             <div className=" flex justify-between flex-col h-[65px] py-5 px-3 items-start" key={index}>
//               <div className="font-normal">{item.title || "N/A"}</div>
//               <div className={`flex justify-start font-semibold text-xs    w-full items-center ${item.cssLabel || ''}`}>
//                 {item.label}
//               </div>
//             </div>
//           ))}
//         </div>

//         {/* Second column */}
//         <div className="space-y-0 bg-white  shadow-md rounded-[4px]">
//           {CommercialData.slice(6, 11).map((item, index) => (
//             <div className=" flex justify-between flex-col h-[65px] py-5 px-3 items-start" key={index}>
//               <div className="font-normal">{item.title}</div>
//               <div className={`flex justify-start  font-semibold text-xs    w-full items-center ${item.cssLabel || ''}`}>
//                 {item.label}
//               </div>
//             </div>
//           ))}
//         </div>

//         {/* Third column */}
//         <div className="space-y-0 bg-white  shadow-md rounded-[4px]">
//           {CommercialData.slice(11, 16).map((item, index) => (
//             <div className=" flex justify-between flex-col h-[65px] py-5 px-3 items-start" key={index}>
//               <div className="font-normal">{item.title}</div>
//               <div className={`flex justify-start  font-semibold text-xs    w-full items-center ${item.cssLabel || ''}`}>
//                 {item.label}
//               </div>
//             </div>
//           ))}
//         </div>

//         {/* Fourth column */}
//         <div className="space-y-0 bg-white  shadow-md rounded-[4px]">
//           {CommercialData.slice(16).map((item, index) => (
//             <div className=" flex justify-between flex-col h-[65px] py-5 px-3 items-start" key={index}>
//               <div className="font-normal">{item.title}</div>
//               <div className={`flex justify-start  font-semibold text-xs    w-full items-center ${item.cssLabel || ''}`}>
//                 {item.label}
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//     </> : <div className="text-xs px-10">No Data Available</div>}

   
//     </>
//   );
// };

// export default AssetCommercial;


import React from "react";
import { currency, formatDate } from "./FormatDate";
import { Grid, Paper, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const AssetCommercial = ({ commercialDetails, assetDetails }) => {
  const TotalLandedCost = parseFloat(commercialDetails[0]?.total_landed_cost).toFixed(2);
  const TotalLandedCostWithGST = parseFloat(commercialDetails[0]?.total_landed_cost_with_gst).toFixed(2);
  const BOENum = commercialDetails[0]?.boe_no;
  const insurance = commercialDetails[0]?.insurance_renewal
    ? formatDate(parseInt(commercialDetails[0]?.insurance_renewal))
    : "--";

  const CommercialData = [
    { title: "Total Landed Cost", label: `₹${currency(TotalLandedCost)}` || "N/A" },
    { title: "Ex-Works Price", label: `$${currency(commercialDetails[0]?.exworks_price)}` || "N/A" },
    { title: "CIF Charges", label: `$${currency(commercialDetails[0]?.cif_charges)}` || "N/A" },
    { title: "Total Cost (Ex-Works + CIF)", label: `$${currency(commercialDetails[0]?.total_cost)}` || "N/A" },
    { title: "Port of Clearance", label: commercialDetails[0]?.port_of_clearance || "N/A" },
    { title: "Date of Clearance", label: assetDetails?.custom_duty_date_of_clearance || "N/A"},  // Example with no data
    { title: "Purchase Order No", label: commercialDetails[0]?.purchase_order_no || "N/A" },
    { title: "Purchase Order Date", label: formatDate(commercialDetails[0]?.purchase_order_date) || "N/A" },
    { title: "Insurance Renewal", label: insurance || "N/A" },
    { title: "Exchange Rate (at Remittance)", label: `₹${commercialDetails[0]?.exchange_rate_rem}` || "N/A" },
    { title: "Amount of Remitted OEM", label: `₹${currency(commercialDetails[0]?.amount_rem_to_oem)}` || "N/A" },
    { title: "Date of Remittance to OEM", label: formatDate(commercialDetails[0]?.date_of_rem_to_oem) || "N/A" },
    { title: "BOE No", label: BOENum || "N/A" },
    { title: "Exchange Rate (at BOE)", label: `₹${commercialDetails[0]?.exrate_boe}` || "N/A" },
    { title: "Custom Duty Value", label: `₹${currency(commercialDetails[0]?.custom_duty_value)}` || "N/A" },
    { title: "Clearing Charges", label: `₹${currency(commercialDetails[0]?.clearing_charges)}` || "N/A" },
    { title: "CHA Charge", label: `₹${currency(commercialDetails[0]?.cha_charges)}` || "N/A" },
    { title: "GST Amount", label: `${commercialDetails[0]?.gst_amount === null || "nill" ? 'N/A' : `₹${currency(commercialDetails[0]?.gst_amount)}`}` },
    { title: "Total Landed Cost with GST", label: `${TotalLandedCostWithGST === null || "nill" ? 'N/A' : `₹${currency(TotalLandedCostWithGST)}`}` },
    { title: "Transportation Charges (INR)", label: commercialDetails[0]?.transportation_charges ? `₹${currency(commercialDetails[0]?.transportation_charges)}` : "N/A" }
  ];

  return (
    <>
      {CommercialData.length > 0 ? (
        <Box p={3}>
          <h1 className="text-base font-semibold text-dark-6">
            Asset Commercial Details
          </h1>
          <Grid container spacing={2}>
            {/* First Column */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={2} sx={{ p: 2 }}>
                {CommercialData.slice(0, 6).map((item, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'normal' }}>
                      {item.title || "N/A"}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '12px' }}>
                      {item.label}
                    </Typography>
                  </Box>
                ))}
              </Paper>
            </Grid>

            {/* Second Column */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={2} sx={{ p: 2 }}>
                {CommercialData.slice(6, 11).map((item, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'normal' }}>
                      {item.title}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '12px' }}>
                      {item.label}
                    </Typography>
                  </Box>
                ))}
              </Paper>
            </Grid>

            {/* Third Column */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={2} sx={{ p: 2 }}>
                {CommercialData.slice(11, 16).map((item, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'normal' }}>
                      {item.title}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '12px' }}>
                      {item.label}
                    </Typography>
                  </Box>
                ))}
              </Paper>
            </Grid>

            {/* Fourth Column */}
            <Grid item xs={12} sm={6} md={3}>
              <Paper elevation={2} sx={{ p: 2 }}>
                {CommercialData.slice(16).map((item, index) => (
                  <Box key={index} sx={{ mb: 2 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'normal' }}>
                      {item.title}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '12px' }}>
                      {item.label}
                    </Typography>
                  </Box>
                ))}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box sx={{ p: 3, textAlign: "center" }}>
          <Typography variant="body2" color="textSecondary">
            No Data Available
          </Typography>
        </Box>
      )}
    </>
  );
};

export default AssetCommercial;
