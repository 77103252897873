import axios from "axios";
import React, { useEffect, useState } from "react";
import Loader from "../../Loader";
import { CurrentItems } from "./CurrentItems";
import { setBaseUrl } from "../../../config";
import { Pagination } from "../Pagination";
import { useNavigate } from "react-router-dom";
import LeaseSearch from "../../Search/LeaseSearch";
import { capitalizeFirstLetter, formatDate } from "../../Modals/AssetDetails/Tabs/FormatDate";
import { CiFilter } from "react-icons/ci";
import { IoMdAdd } from "react-icons/io";
import { CiViewTable } from "react-icons/ci";
import { IoIosClose } from "react-icons/io";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { BiSortAlt2 } from "react-icons/bi";
import { Tooltip } from "react-tooltip";
import HistoryBtn from "../../Buttons/HistoryBtn";
import ExcelReportBtn from "../../Buttons/ExcelReportBtn";
import NewLeaseTable from "./NewLeaseTable";
import LeaseTableHeader from "./LeaseTableHeader";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

function LeaseTable({ tabNo, setTabNo, leaseId, setLeaseId }) {
  // const navigate = useNavigate();
  // set the item quantity to load page numbers
  // const [items, setItems] = useState(0);
  // set items per page

  const [leaseDetails, setLeaseDetails] = useState([]);
  const [loader, setLoader] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [itemOffset] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  //set the state of filter
  const [filter, setFilter] = useState("rso_ids");
  //setting state for search suggestions and page count for pagination
  const [searchSuggetions, setSearchSuggetions] = useState({});
  const [searchType, setSearchType] = useState("rso-id");
  const [searchTypeArray, setSearchTypeArray] = useState([]);
  const [showSearchDiv, setShowSearchDiv] = useState([]);
  const [customerArray, setCustomerArray] = useState([]);
  // for filtering data
  const [filteredDataStack, setFilteredDataStack] = useState([]);

  // for search in lease
  const getSearchData = async () => {
    try {
      const { data } = await axios.get(`${setBaseUrl}/lease/search`, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": sessionStorage.getItem("token"),
        },
      });

      setSearchSuggetions(await data);
      //  console.log(searchSuggetions.customer_name)
    } catch (error) {
      console.error(error);
    }
  };

  // const getAllLease = async (searchId) => {
  //   // console.log(searchId);
  //   // console.log(itemOffset);
  //   try {
  //     setLoader(true);
  //     const headersobj = {
  //       "Content-Type": "application/json",
  //       "x-access-tokens": sessionStorage.getItem("token"),
  //       offset: itemOffset,
  //       // limit: itemsPerPage,
  //     };
  //     console.log(searchId);
  //     if ((searchType !== "") | undefined && (searchId !== "") | null) {
  //              if(searchType === "schedule_date") {
  //         headersobj["start-date"] = startDate;
  //         headersobj["end-date"] = endDate;
  //       } else if (searchType === "customer_name") {
  //         headersobj[`customer_name`] = searchId;
  //       } else {
  //         headersobj[`${searchType}`] = searchId;
  //       }
  //     }
  //     // console.log(headersobj);
  //     const { data } = await axios.get(`${setBaseUrl}/lease/get_all`, {
  //       headers: headersobj,
  //     });
  //     // console.log(data);
  //     setLeaseDetails(data);
  //   } catch (error) {
  //     // console.error(error); 
  //     alert(error.response.data.error);
  //   } finally {
  //     setShowSearchDiv([]);
  //     setLoader(false);
  //   }
  // };

  // console.log(Object.keys(searchSuggetions?.customer_name));


  const getAllLease = async (searchId) => {
    try {
      setLoader(true);
      const headersobj = {
        "Content-Type": "application/json",
        "x-access-tokens": sessionStorage.getItem("token"),
        offset: itemOffset,
      };
  
      if ((searchType !== "") | undefined && (searchId !== "") | null) {
        if (searchType === "schedule_date") {
          headersobj["start-date"] = startDate;
          headersobj["end-date"] = endDate;
        } else if (searchType === "customer_name") {
          headersobj[`customer_name`] = searchId;
        } else {
          headersobj[`${searchType}`] = searchId;
        }
      }
  
      const { data } = await axios.get(`${setBaseUrl}/lease/get_all`, {
        headers: headersobj,
      });
  
      // Modify the data to split the customer field into customer_name and gst_no
      const modifiedLeaseDetails = data?.map(lease => {
        const [customer_name, gst_no] = lease.customer.split(" - ");
        return {
          ...lease,
          customer_name,  // Customer name before the " - "
          gst_no          // GST number after the " - "
        };
      });
  
      setLeaseDetails(modifiedLeaseDetails);
    } catch (error) {
      alert(error.response?.data?.error || "An error occurred");
    } finally {
      setShowSearchDiv([]);
      setLoader(false);
    }
  };

  
// console.log(leaseDetails)

  const handleSearchCategory = () => {
    if (!filter || !searchSuggetions) return;
  
    switch (filter) {
      case "assetnumber":
        setSearchType("asset-id");
        setSearchTypeArray(searchSuggetions?.asset_no || []);
        break;
  
      case "rso_ids":
        setSearchType("rso-id");
        setSearchTypeArray(searchSuggetions?.rso_ids || []);
        break;
  
      case "customer_name":
        setSearchType("customer_name");
        const customerData = searchSuggetions?.customer_name || {};
        setSearchTypeArray(
          Array.isArray(customerData) 
            ? customerData 
            : Object.entries(customerData).map(([no, id]) => ({ no, id }))
        );
        break;
  
      default:
        setSearchTypeArray([]);
    }
  };
  
      
      useEffect(() => {
        handleSearchCategory();
      }, [searchSuggetions]);
      
      // console.log(searchTypeArray);
  
  // console.log(customerArray)

  //  end Date
  // const handleEndDate = (e) => {
  //   let end_date = e.target.value;
  //   if (end_date < startDate) {
  //     alert("End date should be older than start date");
  //     return;
  //   }
  //   setEndDate(end_date);
  // };

  let convertedArray = []
  //on search input onChange event
  const handleSearch = (e) => {
    search(e.target.value);
    setSearchValue(e.target.value);
  };
  
  // function search(query) {
  //   if (query == "") {
  //     setShowSearchDiv([]);
  //     return;
  //   }
    
  //   let filteredData = [];
    
  //   // console.log(searchType)
    
  //   // console.log(searchTypeArray.no)
  //   if (searchTypeArray.length > 0) {
  //      {
  //       filteredData = searchTypeArray?.filter((item) => {
          
  //         if(item && item?.toLowerCase().includes(query.toLowerCase())) {
  //           return item
  //         } else if (!item?.no && item?.toLowerCase().includes(query.toLowerCase()))
  //            { return item;}
  //          else if (
  //             item?.no &&
  //             item.no?.toLowerCase().includes(query.toLowerCase())
  //           ) {return item;}

          
  //       });
  //     }
     

  //     console.log(filteredData);
      
  //     if (filteredData.length > 0) {
  //       // console.log(filteredData);
  //       setShowSearchDiv(filteredData);
  //     } else {
  //       setShowSearchDiv(["no results"]);
  //     }
  //   }
  //   // console.log(showSearchDiv)
  //   // console.log(showSearchDiv);
  // }
  //clicking on show search Div
 
  const search = (query) => {
    if (!query) {
      setShowSearchDiv([]);
      return;
    }
  
    let filteredData = [];
  
    if (Array.isArray(searchTypeArray) && searchTypeArray.length > 0) {
      filteredData = searchTypeArray.filter((item) => {
        if (!item) return false;
        const itemString = typeof item === "string" ? item : JSON.stringify(item);
        return itemString.toLowerCase().includes(query.toLowerCase());
      });
    } else if (searchTypeArray && typeof searchTypeArray === "object") {
      filteredData = Object.entries(searchTypeArray)
        .map(([no, id]) => ({ no, id }))
        .filter((item) =>
          item.no.toLowerCase().includes(query.toLowerCase())
        );
    }
  
    setShowSearchDiv(filteredData.length > 0 ? filteredData : ["No Results"]);
  };
  
  const handleSearchClick = (id) => {
    // console.log(id);
    // setGetSearchList((prev) => !prev);
    getAllLease(id);
  };
  useEffect(() => {
    handleSearchCategory();
  }, [filter, searchSuggetions]);

  

  useEffect(() => {
    getSearchData();
    getAllLease();
    // console.log(leaseDetails);
  }, [tabNo, deleted, itemOffset]);

  // let selected = ["all Lease", "active", "inactive", "expiring", "expired"];
  let selected = [
    {
      title: "All Lease",
      label: "all Lease",
    },
    {
      title: "Active",
      label: ["active", "expiring"],
    },
    {
      title: "Closed",
      label: ["inactive", "never assigned"],
    },
    {
      title: "expired",
      label: ["expired"],
    },
  ];

  const [selectedItem, setSelectedItem] = useState("all Lease");
  const [openFilter, setOpenFilter] = useState(false);
  const [openTable, setopenTable] = useState(false);
  const [openFilterStatus, setFilterStatus] = useState(false);


  const [ascending, setAscending] = useState(null);
  const [descending, setDescending] = useState(null);
  const [open, setOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  // header of the table
  const sortHeader = [
    "Asset No",
    "RSO No",
    "Customer Name",
    "GST",
    "Total Amount",
    "Start Date",
    "End Date",
    "Status",
    // "Delete"
  ];

  const [openSort, setOpenSort] = useState(false);
  // sort names are storing
  const [sort, setSort] = useState("");
  // for input in sorting
  const [inputSearch, setInputSearch] = useState("");

  // sorting the data in ascending order using the whole filter
  const [sortOrder, setSortOrder] = useState("asc");

  // Filtered header based on search input
  const filteredHeaders = sortHeader.filter((header) => {
    header.toLowerCase().includes(inputSearch.toLowerCase());
    //  console.log(header.toLowerCase())
  });

  // const {sortedLeaseDetails, setSortedLeaseDetails} = useState(leaseDetails)
  let sortedLeaseDetails = [];

  const activeCount = leaseDetails.filter(
    (item) =>
      item?.lease_status?.startsWith("expiring") ||
      item?.lease_status === "active"
  ).length;

  // const inactiveCount = leaseDetails.filter(item => item?.lease_status === 'inactive').length;
  const expiredCount = leaseDetails.filter(
    (item) => item?.lease_status === "expired"
  ).length;

  const [filteredData, setFilteredData] = useState(leaseDetails);
  const [selectedRows, setSelectedRows] = useState([]);

  const date = new Date()
  // console.log(formatDate(date))
  const handleExport = () => {
    // Ensure `selectedRows` contains full row objects, not just IDs
    const dataToExport = filteredData.map((item, index) => ({ ...item, id: index + 1 })); // Add IDs if not present
  
    // Validate the rows to ensure there are no missing data
    if (dataToExport.some((row) => !row)) {
      console.error("Error: Some selected rows are missing or invalid.");
      return;
    }
  
    // Create a worksheet from the data
    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
  
    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  
    // Export the workbook as an Excel file
    XLSX.writeFile(workbook, `lease_data_${formatDate(date)}.xlsx`);
    toast.success("Data exported successfully");
  };


  // console.log(showSearchDiv)
  let tableArray = [10, 50, 100, 150,200, leaseDetails.length];
  const [selectTableData, setSelectTableData] = useState(10);

  return (
    <>
      <LeaseTableHeader
        activeCount={activeCount}
        expiredCount={expiredCount}
        leaseDetails={leaseDetails}
      />
      <div className="flex justify-between items-center pb-2">
        <div className="flex justify-start gap-3">
        
          {/* Sorting Suggestions */}
          {/* <button
            className="border-light-2  flex items-center text-[10px] px-2 py-0.5 rounded-[4px] shadow-md bg-white text-light-2 cursor-pointer "
            onClick={() => setOpenSort(!openSort)}
          >
            <BiSortAlt2 className="h-4 w-4 rounded  " />
            Sort
          </button> */}

          {/* filter */}
          {/* <button
            className={`border-light-2  flex items-center text-[10px] px-2 py-0.5 rounded-[4px] shadow-md bg-white text-light-2 cursor-pointer ${
              openFilter && ""
            }`}
            onClick={() => setOpenFilter(true)}
          >
            <CiFilter className="w-4 h-4 cursor-pointer " />
            Filter
          </button> */}
        </div>
        {/*  */}

        {/*  */}
        <div className=" flex justify-center items-center ml-auto gap-1">
        <div className="flex  space-x-2 text-xs z-20">
      
          {ascending && (
            <>
              <button className="shadow-md bg-white  px-1.5 h-6 flex items-center rounded-full text-[10px] text-purple-1">
                {ascending}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setAscending(null)}
                />
              </button>
            </>
          )}

          {descending && (
            <>
              <button className="shadow-md bg-white  px-1.5 h-6 flex items-center rounded-full text-[10px] text-purple-1">
                {descending}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setDescending(null)}
                />
              </button>
            </>
          )}
          {selectedItem !== "all Lease" && (
            <>
              <button className="shadow-md bg-white  px-1.5 h-6 flex items-center rounded-full text-[10px] text-purple-1">
                {selectedItem[0]}
                <IoIosClose
                  className="h-4 w-4"
                  onClick={() => setSelectedItem("all Lease")}
                />
              </button>
            </>
          )}
          {/* Sorted Data */}
          {/* {sort && (
            <>
              <button className="shadow-md bg-white  px-1.5 h-6 flex items-center rounded-full text-[10px] text-purple-1">
                {sort}
                <IoIosClose
                  className="w-4 h-4 cursor-pointer"
                  onClick={() => setSort(null)}
                />
              </button>
            </>
          )} */}

          {/* Table */}
          {openTable && (
            <>
              <div
                className="absolute mt-5 z-[9999] right-[10%] text-xs
                flex flex-col justify-center transition-all ease-in duration-200 mx-1
                bg-white  w-[60px] shadow-2xl p-1 rounded-lg h-fit items-center"
              >
                {tableArray.map((table) => (
                  <>
                    {" "}
                    <button
                      key={table}
                      className="hover:bg-[#EEE] w-full border-b py-1 dark:hover:text-black "
                      onClick={() => setSelectTableData(table)}
                    >
                      {table}
                    </button>{" "}
                  </>
                ))}
                <button className="text-xs hover:bg-[#eee] py-1">all data</button>
              </div>
              <div
                className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-50 "
                onClick={() => setopenTable(false)}
              />
            </>
          )}

          {/* Filter Table */}
          {openFilter && (
            <>
              <div
                className="absolute mt-0 z-[9999] left-[6%] top-[22%]
                flex flex-col justify-center  pt-6 shadow-2xl mx-1
                bg-white dark:bg-[#070e18]  w-[150px]  rounded-lg h-fit items-center"
              >
                <button
                  className="hover:bg-[#EBEBEB] w-full border-b border-t dark:hover:text-black py-2"
                  onClick={() => setFilterStatus(true)}
                >
                  Status
                </button>
                <button className="flex items-center justify-start py-2">
                  <IoMdAdd />
                  filter
                </button>
              </div>

              {openFilterStatus && (
                <>
                  <div
                    onClick={() => setFilterStatus(false)}
                    className="absolute mt-9 z-[9999] left-[18%] top-[16%]
                flex flex-col justify-center  pt-6 shadow-2xl 
                bg-white  w-[150px] border rounded-lg h-fit items-center 
                "
                  >
                    {selected.map((select) => {
                      return (
                        <>
                          <button
                            className={`hover:bg-[#EBEBEB] dark:hover:text-black w-full border-b py-2 ${
                              selectedItem === select
                                ? "bg-[#b9b8b8] dark:text-black"
                                : ""
                            }  `}
                            onClick={() =>
                              setSelectedItem([select.title, select.label])
                            }
                            key={select}
                          >
                            {capitalizeFirstLetter(select.title)}
                          </button>
                        </>
                      );
                    })}

                    <button className="flex items-center justify-start py-2">
                      <IoMdAdd />
                      Status
                    </button>
                  </div>
                </>
              )}

              <div
                className="fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-50 "
                onClick={() => setOpenFilter(false)}
              />
            </>
          )}
          {/* Sort Table */}
          {openSort && (
            <>
              <div className="absolute text-xs w-[200px] h-fit z-30 top-[22%] left-[1%] rounded-lg p-2 border bg-white">
                <input
                  type="text"
                  className="bg-transparent w-[180px] h-7 rounded-md text-xs"
                  placeholder="Search..."
                  onChange={(e) => setInputSearch(e.target.value)}
                  value={inputSearch}
                />
                <ul className="mt-2">
                  {filteredHeaders.length > 0 ? (
                    filteredHeaders.map((header) => (
                      <li
                        className="px-2 py-2 hover:bg-[#EEE] rounded-md cursor-pointer"
                        key={header}
                        onClick={() => {
                          setSort(header);
                          setOpenSort(false);
                        }}
                      >
                        {header}
                      </li>
                    ))
                  ) : (
                    <li className="px-2 py-2 text-gray-500">
                      No results found
                    </li>
                  )}
                </ul>
              </div>
              <div
                className={`fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-10 ${
                  !openSort && "hidden"
                } `}
                onClick={() => {
                  setOpenSort(false);
                }}
              />
            </>
          )}
        </div>
          {/* {searchType !== "schedule_date" && (
            <div className="relative flex flex-col gap-1 z-[5000]">
              <LeaseSearch
                setFilter={setFilter}
                handleSearch={handleSearch}
                handleSearchClick={handleSearchClick}
                searchValue={searchValue}
                open={open}
                setOpen={setOpen}
                setOpenSearch={setOpenSearch}
              />

              {searchType === "rso-id" ? (
                <>
                  {openSearch && showSearchDiv.length > 0 && (
                    <div className="absolute z-[9999] bg-white p-2 border h-[50vh]  shadow-xl  w-[200px] rounded-[4px] hide-scrollbar mt-10 overflow-y-scroll right-4">
                      {showSearchDiv.map((item) => (
                        <p
                          onClick={() => handleSearchClick(item)}
                          className="cursor-pointer hover:bg-[#EEE] text-xs border-b  p-2"
                        >
                          {item !== undefined ? `${item}` : "No Results"}
                        </p>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {openSearch && showSearchDiv.length > 0 && (
                    <div className="absolute z-[9999] bg-white p-2 border h-[50vh]  shadow-xl rounded-[4px] w-[200px] hide-scrollbar mt-10 overflow-y-scroll right-4">
                      {showSearchDiv.map((item) => (
                        <p
                          onClick={() => handleSearchClick(item?.id)}
                          className="cursor-pointer hover:bg-[#EEE] text-xs border-b  p-2"
                        >
                          {item?.no !== undefined ? `${item.no}` : "No Results"}
                        </p>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          )} */}

          {/* Search Function with date */}
          {/* {searchType === "schedule_date" && (
            <>
              <input
                type="date"
                id="datesmaintenance"
                name="dates"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="rounded-lg bg-slate-100 border-slate-400 text-sm text-slate-700"
              />
              <span className="mx-4"> to </span>
              <input
                type="date"
                id="datesmaintenance"
                name="dates"
                value={endDate}
                onChange={handleEndDate}
                className="rounded-lg bg-slate-100 border-slate-400 text-sm text-slate-700"
              />
              <button
                onClick={handleSearchClick}
                className="bg-[#1920d5] p-2 mx-4 rounded-lg text-white"
              >
                Search
              </button>
            </>
          )} */}

          <div
            className={`fixed top-0 left-0 bottom-0 right-0 bg-[#00000006] z-10 ${
              !open && "hidden"
            } `}
            onClick={() => {
              setOpen(false);
              setOpenSearch(false);
            }}
          />
          
        </div>
      </div>

      {loader ? (
        <Loader />
      ) : (
        // <CurrentItems
        //   setTabNo={setTabNo}
        //   leaseDetails={leaseDetails}
        //   leaseId={leaseId}
        //   setLeaseId={setLeaseId}
        //   loader={loader}
        //   setLoader={setLoader}
        //   deleted={deleted}
        //   setDeleted={setDeleted}
        //   selected={selected}
        //   selectedItem={selectedItem}
        //   setSelectedItem={setSelectedItem}
        //   selectTableData={selectTableData}
        //   sortHeader={sortHeader}
        //   sort={sort}
        //   setSort={setSort}
        //   sortOrder={sortOrder}
        //   setSortOrder={setSortOrder}
        //   filteredDataStack={filteredDataStack}
        //   setFilteredDataStack={setFilteredDataStack}
        //   sortedLeaseDetails={sortedLeaseDetails}
        //   // setSortedLeaseDetails={setSortedLeaseDetails}
        //   tableArray={tableArray}
        //   openTable={openTable}
        //   setOpenTable={openTable}
        //   setSelectTableData={setSelectTableData}

        // />
      <NewLeaseTable
        handleExport={handleExport}
        selected={selected}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        leaseDetails={leaseDetails}
      />
      )}
      {/* Bottom Button */}
  
    </>
  );
}

export default LeaseTable;
