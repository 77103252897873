import React, { useEffect, useRef, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { Button, FormControl, MenuItem, Select, Tooltip } from "@mui/material";
import { IoIosClose } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { capitalizeFirstLetter } from "../../Modals/AssetDetails/Tabs/FormatDate";


const NewLeaseTable = ({
  handleExport,
  filteredData,
  setFilteredData,
  leaseDetails,
  setSelectedRows,
}) => {
  const [page, setPage] = useState(0); // Current page
  const [pageSize, setPageSize] = useState(10); // Rows per page
  const [searchQuery, setSearchQuery] = useState(""); // Search query
  const [selectedFilter, setSelectedFilter] = useState("All Lease"); // Default to "All Lease"

  const selected = [
    { title: "All Lease", label: "all" },
    { title: "Active", label: ["active", "expiring"] },
    { title: "Closed", label: ["inactive", "never assigned"] },
    { title: "Expired", label: ["expired"] },
  ];

  const applyFilters = () => {
    let data = [...leaseDetails];

  // Filter by selected filter
  if (selectedFilter !== "All Lease") {
    const selectedLabels =
      selected.find((item) => item.title === selectedFilter)?.label || [];

    data = data.filter((item) => {
      const leaseStatus = item.lease_status.toLowerCase();

      // Exclude "inactive" for all filters except "Closed"
      if (
        selectedFilter !== "Closed" &&
        leaseStatus.includes("inactive")
      ) {
        return false; // Exclude inactive unless the filter is "Closed"
      }

      // Match lease statuses for the selected labels
      if (Array.isArray(selectedLabels)) {
        return selectedLabels.some((label) =>
          leaseStatus.includes(label.toLowerCase()) // Ensure case-insensitive match
        );
      }

      return true; // Default case (shouldn't occur if labels are set correctly)
    });
  }
    

    // Apply search
    if (searchQuery.trim()) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      data = data.filter((item) =>
        Object.values(item).some(
          (value) =>
            value && value.toString().toLowerCase().includes(lowerCaseQuery)
        )
      );
    }

    setFilteredData(data);
    setPage(0); // Reset to the first page
  };

  useEffect(() => {
    applyFilters(); // Initial filter and search application
  }, [leaseDetails, selectedFilter, searchQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page
  };

  const navigate = useNavigate();

 // Import Tooltip component
 const formatDate = (dateString) => {
//  console.log(dateString)
if (!dateString) return "N/A"; // Handle empty or null values
const date = new Date(dateString);
const day = String(date.getDate()).padStart(2, "0"); // Ensure two-digit day
const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure two-digit month
const year = date.getFullYear();
return `${day}-${month}-${year}`;
};

  const columns = [
    {
      field: "counter",
      headerName: "#",
      width: 80,
      renderHeader: () => (
        <Tooltip title="Row Number" arrow placement="top">
          <span>#</span>
        </Tooltip>
      ),
      renderCell: (params) => <>{params.row.counter}</>, // Use the counter field
    },
    {
      field: "asset_no",
      headerName: "Asset No",
      width: 120,
      renderHeader: () => (
        <Tooltip title="Unique identifier for the asset" arrow placement="top">
          <span>Asset No</span>
        </Tooltip>
      ),
    },
    {
      field: "odoo_order_id",
      headerName: "RSO NO",
      width: 150,
      renderHeader: () => (
        <Tooltip title="Unique RSO No. Generated by Odoo" arrow placement="top">
          <span>RSO NO</span>
        </Tooltip>
      ),
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      width: 150,
      renderHeader: () => (
        <Tooltip title="Name of the customer" arrow placement="top">
          <span>Customer Name</span>
        </Tooltip>
      ),
    },
    {
      field: "gst_no",
      headerName: "GST No",
      width: 125,
      renderHeader: () => (
        <Tooltip title="GST number of the customer" arrow placement="top">
          <span>GST No</span>
        </Tooltip>
      ),
    },
    {
      field: "total_claimable_amount",
      headerName: "Total Amount",
      width: 150,
      renderHeader: () => (
        <Tooltip title="Total claimable amount for the asset" arrow placement="top">
          <span>Total Amount</span>
        </Tooltip>
      ),
      renderCell: (params) => {
        let amount = params.value
        return (
          <>{amount === null || amount === 0 || amount === "0" || amount === "" ? "N/A" : `₹${amount}`}</>
        )  
        
      }
    },
    {
      field: "rental_start_date",
      headerName: "Rental Start Date",
      width: 150,
      renderHeader: () => (
        <Tooltip title="Start date of the rental period" arrow placement="top">
          <span>Rental Start Date</span>
        </Tooltip>
      ),
      
      renderCell: (params) => {
        let date = params.value
        
        return (
          <>{formatDate(date)}</>
        )
      }
    },
    {
      field: "rental_end_date",
      headerName: "Rental End Date",
      width: 120,
      renderHeader: () => (
        <Tooltip title="End date of the rental period" arrow placement="top">
          <span>Rental End Date</span>
        </Tooltip>
      ),
      renderCell: (params) => {
        let date = params.value
        
        return (
          <>{formatDate(date)}</>
        )
      }
    },
    {
      field: "lease_status",
      headerName: "Lease Status",
      width: 150,
      renderHeader: () => (
        <Tooltip title="Current status of the lease" arrow placement="top">
          <span>Lease Status</span>
        </Tooltip>
      ),
      renderCell: (params) => {
        let status = params.value;
        const color =
          status === "inactive"
            ? "gray"
            : status === "expired"
            ? "red"
            : "green";
  
        let activeStatus = "Active";
        if (status.toLowerCase().includes("expiring")) {
          const daysMatch = status.match(/(\d+)\s+days/);
          if (daysMatch && daysMatch[1]) {
            const daysLeft = daysMatch[1]; // Extracted number of days
            activeStatus = `Active (${daysLeft} days left)`;
          }
        }
  
        return (
          <button
            className="text-xs"
            style={{
              color: color,
              fontWeight: "bold",
              padding: "4px 8px",
            }}
          >
            {status === "inactive"
              ? "Closed"
              : status.toLowerCase().includes("expiring")
              ? activeStatus
              : capitalizeFirstLetter(status)}
          </button>
        );
      },
    },
    // {
    //   field: "details",
    //   headerName: "See More",
    //   width: 120,
    //   renderHeader: () => (
    //     <Tooltip title="View more details of the lease" arrow placement="top">
    //       <span>See More</span>
    //     </Tooltip>
    //   ),
    //   renderCell: (params) => (
    //     <Button
    //       onClick={() => navigate(`/lease/leaseDetails/${params.row.id}`)}
    //       variant="contained"
    //       sx={{
    //         height: "32px",
    //         width: "96px",
    //         fontSize: 12,
    //         textTransform: "capitalize",
    //         "&:hover": {
    //           backgroundColor: "#004ba0", // Hover effect color
    //         },
    //       }}
    //     >
    //       See Details
    //     </Button>
    //   ),
    // },
  ];
  

  return (
    <div className="fixed right-3 left-[5%] bg-white">
      <Paper
        style={{
          padding: 8,
          height: "65vh",
          width: "100%",
          border: "hidden",
        }}
        elevation={0}
      >
        <div className="flex justify-between items-center my-2  ">
          {/* Search and Filter */}
          <div className="flex gap-2 items-center">
            <Tooltip placement="right" arrow title="Search Rso No, Asset No, Company etc">

            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && applyFilters()}
              placeholder="Search leases"
              style={{
                height: 30,
                width: 200,
                padding: "5px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                fontSize: 12,
              }}
              className="mt-0.5"
              />
              </Tooltip>

            <FormControl
              fullWidth
              sx={{
                "& .MuiInputLabel-root": { fontSize: "12px" },
                "& .MuiSelect-select": { fontSize: "12px" },
                "& .MuiMenuItem-root": { fontSize: "12px" },
                "& .MuiInputBase-root": {
                  backgroundColor: "#fff",
                },
                "& .MuiInputBase-root:hover": {
                  backgroundColor: "#fff",
                },
              }}
            >
              <Tooltip title="Filter Lease Status" placement="top" arrow>

              <Select
                // className="border p-1 rounded-md text-xs h-[30px] mt-1 border-light-5 focus:ring-0 w-[200px] text-left"
                value={selectedFilter}
                onChange={(e) => setSelectedFilter(e.target.value)}
                style={{
                  height: 30,
                  width: 300,
                  fontSize: 10,
                }}
                >
                {selected.map((option) => (
                  <MenuItem key={option.title} value={option.title}>
                    {option.title}
                  </MenuItem>
                ))}
              </Select>
                </Tooltip>
            </FormControl>
          </div>
          <div className="flex gap-2 items-center">
            <button
              className={`shadow-md bg-white  px-1.5 h-6 flex items-center rounded-full text-[10px] text-purple-1 ${
                selectedFilter === "All Lease" && "hidden"
              }`}
            >
              {selectedFilter}
              <IoIosClose
                className="h-4 w-4"
                onClick={() => setSelectedFilter("All Lease")}
              />
            </button>
            <Tooltip title="Add pagination" placement="top" arrow>

            <TablePagination
              component="div"
              count={filteredData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 30, 50, filteredData.length]}
              labelRowsPerPage="Rows:"
              />
              </Tooltip>
            <NewAssetBtn tabName="lease" />
              <Tooltip title="Export Data in CSV format" placement="top" arrow>
            <button
              variant="contained"
              onClick={handleExport}
              className="buttons border "
              >
              Export Export
            </button>
              </Tooltip>
          </div>
        </div>

        <DataGrid
          //  rows={
            // pageSize === filteredData.length
            //   ? filteredData // Show all rows when "All" is selected
            //   : filteredData.slice(page * pageSize, page * pageSize + pageSize) // Paginated rows
          // }

          // rows={filteredData.map((row, index) => ({
          //   ...row,
          //   counter: index + 1, // Add a counter field
          // }))}

         rows = { pageSize === filteredData.length
          ? filteredData.map((row, index) => ({
              ...row,
              counter: index + 1, // Add row number for all rows
            }))
          : filteredData
              .slice(page * pageSize, page * pageSize + pageSize) // Paginated rows
              .map((row, index) => ({
                ...row,
                counter: page * pageSize + index + 1, // Adjust counter for paginated rows
              }))}
          columns={columns}
          // checkboxSelection
          paginationMode="server" 
          pageSize={pageSize}
          pagination={false} // Disable default pagination
          onRowClick={(params) => {
            const rowId = params.row.id; // Use the row ID or another property to navigate
            navigate(`/lease/leaseDetails/${rowId}`); // Adjust the route as needed
          }}
          sx={{
            "& .MuiDataGrid-footerContainer": {
              display: "none", // Hides the footer container
            },
            "& .MuiDataGrid-root": { fontSize: "12px", height: 30 },
            "& .MuiDataGrid-columnHeaders": {
              fontSize: "14px",
              backgroundColor: "#000",
              fontWeight: "bold",
              color: "#006DB6",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
              height: 20,
            },
            "& .MuiDataGrid-cell": { fontSize: "12px", cursor: "pointer" },
            "& .MuiDataGrid-virtualScroller": {
              "&::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
            },
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
          // onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
        />
      </Paper>
    </div>
  );
};

export default NewLeaseTable;
