import React, { useEffect, useRef, useState } from "react";
import { Button, Paper, TablePagination, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate } from "react-router-dom";
import NewAssetBtn from "../../Buttons/NewAssetBtn";
import { formatDate } from "../../Modals/AssetDetails/Tabs/FormatDate";

const ButtonData = ({ handleExport, selectedRows, filteredData }) => {
  return (
     <>
     {filteredData.length > 0? 
     
     <button
     variant="contained"
     onClick={handleExport}
     className="border text-black buttons "
     >
      Export CSV
    </button>
    : <button
    className="border text-black bg-light-4 buttons cursor-not-allowed text-[8px] line-through"
    disabled
    >
      Export Excel
      </button>}
      </>
  );
};

const NewOperatorTable = ({
  allOperators,
  setFilteredData,
  selectedRows,
  setSelectedRows,
  handleExport,
}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filteredData, setLocalFilteredData] = useState(allOperators);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  
  const inputRef = useRef(null);
  const navigate = useNavigate();
  

  useEffect(() => {
    setFilteredData(filteredData);
  }, [filteredData, setFilteredData]);

  useEffect(() => {
    setLocalFilteredData(allOperators);
  }, [allOperators]);

  const handleSearch = (query) => {
    const lowerCaseQuery = query.toLowerCase();
    const filtered = allOperators.filter((operator) =>
      Object.values(operator).some((value) =>
        value?.toString().toLowerCase().includes(lowerCaseQuery)
      )
    );
    setLocalFilteredData(filtered);
    setSuggestions([]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch(searchQuery);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleInputChange = (query) => {
    setSearchQuery(query);
    if (query.trim() === "") {
      setSuggestions([]);
    } else {
      const lowerCaseQuery = query.toLowerCase();
      const matches = allOperators
        .filter(
          (operator) =>
            operator.name?.toLowerCase().includes(lowerCaseQuery) ||
            operator.aadhar_no?.toString().includes(lowerCaseQuery) ||
            operator.pf_account_no?.toString().includes(lowerCaseQuery)
        )
        .map((operator) => ({
          name: operator.name,
          aadhar_no: operator.aadhar_no,
          pf_account_no: operator.pf_account_no,
        }));
      setSuggestions(matches.slice(0, 5)); // Limit suggestions to 5
    }
  };

  const preprocessData = (data) =>
    data.map((item) => ({
      ...item,
      pf_account_no: Number.isInteger(item.pf_account_no)
        ? item.pf_account_no
        : parseInt(item.pf_account_no, 10) || "N/A",
      aadhar_no: item.aadhar_no
        ? item.aadhar_no
            .toString()
            .replace(/\D/g, "") // Remove non-numeric characters
            .replace(/(\d{4})(\d{4})(\d{4})/, "$1 $2 $3")
        : "N/A", // Fallback to "n/a" if Aadhaar number is invalid or missing
      joining_date: item.joining_date ? formatDate(item.joining_date) : "N/A",
      leaving_date: item.leaving_date ? formatDate(item.leaving_date) : "N/A",
    }));

  


     const columns = [
       {
            field: "counter",
            headerName: "#",
            width: 80,
            renderHeader: () => (
              <Tooltip title="Row Number" arrow placement="top">
                <span>#</span>
              </Tooltip>
            ),
            renderCell: (params) => <>{params.row.counter}</>, // Use the counter field
          },
      {
        field: "name",
        headerName: "Name",
        width: 180,
        renderHeader: () => (
          <Tooltip title="Name of the operator" arrow  placement="top">
            <span>Name</span>
          </Tooltip>
        ),
      },
      {
        field: "aadhar_no",
        headerName: "Aadhaar Number",
        width: 180,
        renderHeader: () => (
          <Tooltip title="Aadhaar Number of the operator"  arrow placement="top">
            <span>Aadhaar Number</span>
          </Tooltip>
        ),
      },
      {
        field: "pf_account_no",
        headerName: "Pf Account Number",
        width: 180,
        renderHeader: () => (
          <Tooltip title="Provident Fund Account Number of the operator" arrow  placement="top">
            <span>Pf Account Number</span>
          </Tooltip>
        ),
      },
      {
        field: "joining_date",
        headerName: "Joining Date",
        width: 180,
        renderHeader: () => (
          <Tooltip title="Date the operator joined" arrow  placement="top">
            <span>Joining Date</span>
          </Tooltip>
        ),
      },
      {
        field: "leaving_date",
        headerName: "Leaving Date",
        width: 180,
        renderHeader: () => (
          <Tooltip title="Date the operator left" placement="top" arrow>
            <span>Leaving Date</span>
          </Tooltip>
        ),
      },
     
    ];
    



  return (
    <div className="fixed right-3 left-[5%] bg-white">
      <Paper
        style={{
          padding: 10,
          height: "70vh",
          width: "100%",
          border: "hidden",
        }}
        elevation={0}
      >
        <div className="flex justify-between items-center">
          <div className="relative flex gap-2 items-center">
            <div className="flex gap-2 border rounded-[4px]">
              <input
                ref={inputRef}
                type="text"
                placeholder="Search operators"
                value={searchQuery}
                onChange={(e) => handleInputChange(e.target.value)}
                onKeyDown={handleKeyDown}
                style={{
                  height: 30,
                  width: 300,
                  padding: "5px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  fontSize: 12,
                }}
              />
              {suggestions.length > 0 && (
                <ul
                  className="absolute z-10 bg-white border border-gray-300 rounded-md shadow-md max-h-40 overflow-auto"
                  style={{
                    top: "35px",
                    left: 0,
                    width: "300px",
                  }}
                >
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      className="p-2 hover:bg-gray-200 cursor-pointer text-sm"
                      onClick={() => {
                        setSearchQuery(
                          suggestion.name ||
                            suggestion.aadhar_no ||
                            suggestion.pf_account_no
                        );
                        handleSearch(
                          suggestion.name ||
                            suggestion.aadhar_no ||
                            suggestion.pf_account_no
                        );
                      }}
                    >
                      {suggestion.name ||
                        suggestion.aadhar_no ||
                        suggestion.pf_account_no}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          <div className="flex items-center gap-2">
            <TablePagination
              component="div"
              count={filteredData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={pageSize}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
              className="bg-white rounded-lg"
              labelRowsPerPage="Rows:"
            />
            <NewAssetBtn tabName="operator" />
            <ButtonData handleExport={handleExport} selectedRows={selectedRows} filteredData={filteredData} />
          </div>
        </div>

        <DataGrid
          //  rows={
          //   pageSize === filteredData.length
          //     ? filteredData // Show all rows when "All" is selected
          //     : preprocessData(
          //       filteredData.slice(page * pageSize, page * pageSize + pageSize)
          //     )// Paginated rows
          // }

          rows = { pageSize === filteredData.length
            ? filteredData.map((row, index) => ({
                ...row,
                counter: index + 1, // Add row number for all rows
              }))
            : filteredData
                .slice(page * pageSize, page * pageSize + pageSize) // Paginated rows
                .map((row, index) => ({
                  ...row,
                  counter: page * pageSize + index + 1, // Adjust counter for paginated rows
                }))}


          onRowClick={(params) => {
            const rowId = params.row.id; // Use the row ID or another property to navigate
            navigate(`/operators/details/${rowId}`); // Adjust the route as needed
          }}
          columns={columns}
          // checkboxSelection
          paginationMode="server" 
          pagination={false} 
          disableSelectionOnClick
          // sx={{
          //   "& .MuiDataGrid-footerContainer": {
          //     display: "none", // Hides the footer container
          //   },
          //   "& .MuiDataGrid-root": { fontSize: "12px", height: 30 },
          //   "& .MuiDataGrid-columnHeaders": {
          //     fontSize: "14px",
          //     backgroundColor: "#000",
          //     fontWeight: "bold",
          //     color: "#006DB6",
          //   },
          //   "& .MuiDataGrid-columnHeaderTitle": {
          //     fontWeight: "bold",
          //     height: 20,
          //   },
          //   "& .MuiDataGrid-cell": { fontSize: "12px" },
          //   "& .MuiDataGrid-virtualScroller": {
          //     "&::-webkit-scrollbar": {
          //       display: "none",
          //     },
          //     "-ms-overflow-style": "none",
          //     "scrollbar-width": "none",
          //   },
          // }}
          sx={{
            "& .MuiDataGrid-footerContainer": {
              display: "none", // Hides the footer container
            },
            "& .MuiDataGrid-root": { fontSize: "12px", height: 30 },
            "& .MuiDataGrid-columnHeaders": {
              fontSize: "14px",
              backgroundColor: "#000",
              fontWeight: "bold",
              color: "#006DB6",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
              height: 20,
            },
            "& .MuiDataGrid-cell": { fontSize: "12px" , cursor: "pointer"}, 
            "& .MuiDataGrid-virtualScroller": {
              "&::-webkit-scrollbar": {
                display: "none",
              },
              "-ms-overflow-style": "none",
              "scrollbar-width": "none",
            },
            "& .MuiDataGrid-cell:focus": {
              outline: "none",
            },
          }}
          onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
        />
      </Paper>
    </div>
  );
};

export default NewOperatorTable;
