import React from 'react'
import { capitalizeFirstLetter, currency, formatDate } from '../../AssetDetails/Tabs/FormatDate'

const LeaseDashboard = ({leaseSpecificDetails}) => {
  return (
    <>
    <div className="flex flex-col md:flex-row text-xs w-[93vw] justify-between px-4 mt-4 gap-4 dark:bg-slate-900">
  {/* Frame 1: Customer Details */}
  <div className="w-full md:w-[400px] p-2 bg-white shadow-md rounded-[6px]">
    <h2 className="text-xs font-semibold text-dark-6 mb-2 pl-2">Customer Details</h2>
    <div className="space-y-0">
      <div className="flex flex-col  rounded-[4px] p-2">
        <span className="text-dark-6 text-[11px]">Customer PO No</span>
        <span className="font-semibold">
          {leaseSpecificDetails?.customer_po_no === "null" ? "--" : leaseSpecificDetails?.customer_po_no}
        </span>
      </div>
      <div className="flex flex-col  rounded-[4px] p-2">
        <span className="text-dark-6 text-[11px]">Customer</span>
        <span className="font-semibold">{leaseSpecificDetails?.customer}</span>
      </div>
      <div className="flex flex-col  rounded-[4px] p-2">
        <span className="text-dark-6 text-[11px]">Status</span>
        <span
          className={`font-semibold ${
            leaseSpecificDetails?.lease_status === "inactive"
              ? "text-gray-500"
              : leaseSpecificDetails?.lease_status === "never assigned"
              ? "text-red-600"
              : "text-green-500"
          }`}
        >
          {capitalizeFirstLetter(leaseSpecificDetails?.lease_status)}
        </span>
      </div>
      <div className="flex flex-col  rounded-[4px] p-2">
        <span className="text-dark-6 text-[11px]">Rental Start Date</span>
        <span className="font-semibold">{formatDate(leaseSpecificDetails?.rental_start_date)}</span>
      </div>
      <div className="flex flex-col  rounded-[4px] p-2">
        <span className="text-dark-6 text-[11px]">Rental End Date</span>
        <span className="font-semibold">
          {/* {formatDate(leaseSpecificDetails?.rental_end_date)} */}
          {leaseSpecificDetails?.rental_extended_date ? formatDate(leaseSpecificDetails?.rental_extended_date) :
          leaseSpecificDetails?.closed_date ? formatDate(leaseSpecificDetails?.closed_date) :
          formatDate(leaseSpecificDetails?.rental_end_date)}
          </span>
      </div>
     
    </div>
  </div>

  {/* Frame 2: Payment Details */}
  <div className="w-full md:w-[400px] p-2 bg-white shadow-md rounded-[6px]">
    <h2 className="text-xs font-semibold text-dark-6 mb-2 pl-2">Payment Details</h2>
    <div className="space-y-0">
      {[
        { label: "Currency", value: leaseSpecificDetails?.currency },
        { label: "Contract Value", value: `₹${currency(leaseSpecificDetails?.contract_value)}` },
        { label: "Transportation Charges", value: `₹${currency(leaseSpecificDetails?.transportation_charge)}` },
        { label: "Normal Amount", value: `₹${currency(leaseSpecificDetails?.normal_amount)}` },
        { label: "Overtime Amount", value: `₹${currency(leaseSpecificDetails?.overtime_amount)}` },
        { label: "Reimbursements", value: `₹${currency(leaseSpecificDetails?.reimbursements)}` },
        { label: "Total Claimable Amount", value: `₹${currency(leaseSpecificDetails?.total_claimable_amount)}` },
      ].map((item, index) => (
        <div key={index} className="flex flex-col  rounded-[4px] p-2">
          <span className="text-dark-6 text-[11px]">{item.label}</span>
          <span className="font-semibold">{item.value}</span>
        </div>
      ))}
    </div>
  </div>

  {/* Frame 3: Invoice Summary */}
  <div className="w-full md:w-[400px] p-2 bg-white shadow-md rounded-[6px]">
    <h2 className="text-xs font-semibold text-dark-6 mb-2 pl-2">Invoice Summary</h2>
    <div className="space-y-0">
      <div className="flex flex-col  rounded-lg p-2">
        <span className="text-gray-500">Contract Value</span>
        <span className="font-semibold">₹{currency(leaseSpecificDetails?.contract_value)}</span>
      </div>
      {/* Add more sections here as needed */}
    </div>
  </div>
</div>


    </>
  )
}

export default LeaseDashboard

// {/* <div className="flex flex-col  md:flex-row text-xs w-[93vw]
//    justify-between px-2  mt-2 dark:bg-slate-900 gap-2 ">
//         {/* Frame 1 */}
//         <div className="w-2/6 p-4 bg-white shadow-md">


//         {/* <h1 className="">Custormer Details</h1> */}
//         <div className="flex-1 borde mt-2  py-4 round-2xl ">
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l border-t round-t-lg h-[66px]">
//             <span className="text-gray-500">Customer PO No</span>
//             <span className="block font-semibold text-xs">
//                 {leaseSpecificDetails?.customer_po_no === "null" ? '--': leaseSpecificDetails?.customer_po_no}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l  h-[6px]">
//             <span className="text-gray-500">Customer</span>
//             <span className="block font-semibold text-xs">
//                 {leaseSpecificDetails?.customer}
//             </span>
//           </div>
         
               
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l   h-[66px]">
//             <span className="text-gray-500">Status</span>
//             <span className={`block font-semibold text-xs
//                  ${leaseSpecificDetails?.lease_status === 'inactive'? 'text-gray-500': 
//                     leaseSpecificDetails?.lease_status === "never assigned" ? "text-red-600"  : "text-green-500"}`}>
//             {capitalizeFirstLetter(leaseSpecificDetails?.lease_status)}
//             </span>
//           </div>
//            <div className="p-2 flex justify-between flex-col border-b border-r border-l   h-[6px]">
//             <span className="text-gray-500">Rental Start Date</span>
//             <span className="block font-semibold text-xs">
//             {formatDate(leaseSpecificDetails?.rental_start_date)}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l   h-[66px]">
//             <span className="text-gray-500"> Rental End Date</span>
//             <span
//               className={`block font-semibold text-xs`}
//             >
//               {formatDate(leaseSpecificDetails?.rental_end_date)}
              
//             </span>
//           </div>

//           <div className="p-2 flex justify-between flex-col border-b border-r border-l  round-b-lg h-[66px]">
//             <span className="text-gray-500">Rental Extended End Date</span>
//             <span className="block font-semibold text-xs">
//               {leaseSpecificDetails?.rental_extended_date ? 
//                 <>
//             {formatDate(leaseSpecificDetails?.rental_extended_date)}
//                 </> 
//                 : "--"
//                 }
//             </span>
//           </div>
//         </div>
//         </div>

        
//         {/* Frame 2 */}
//         <div className="w-2/6 p-4 bg-white shadow-md">


//         {/* <h1 className="">Pay Term</h1> */}
//         <div className="flex-1  mt-2 py-4 round-2xl">
//         <div className="p-2 flex justify-between flex-col border   h-[66px]">
//             <span className="text-gray-500">Cuerrency</span>
//             <span className="block font-semibold text-xs">
//             {leaseSpecificDetails?.currency}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l border-t round-t-lg h-[66px]">
//             <span className="text-gray-500">Contract Value</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.contract_value)}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l  h-[66px]">
//             <span className="text-gray-500">Transportation Charges</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.transportation_charge)}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l   h-[66px]">
//             <span className="text-gray-500">Normal Amount</span>
//             <span className="block font-semibold text-xs">
//             ₹{(currency(leaseSpecificDetails?.normal_amount))}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l   h-[66px]">
//             <span className="text-gray-500"> Overtime Amount</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.overtime_amount)}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l   h-[66px]">
//             <span className="text-gray-500">  Reimbursements</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.reimbursements)}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between flex-col border-b border-r border-l   h-[66px]">
//             <span className="text-gray-500">  Total Claimable Amount</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.total_claimable_amount)}
//             </span>
//           </div>
//         </div>
//         </div>

// {/* Frame 3 */}
//         <div className="w-2/6 p-4 bg-white shadow-md">


//         {/* <h1 className="">Invoice Details</h1> */}
//         <div className="flex-1 mt-2 py-4 ">
//           <div className="p-2 flex justify-between border-b border-r border-l border-t  h-8">
//             <span className="text-gray-500">Contract Value</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.contract_value)}
//             </span>
//           </div>
//           {/* <div className="p-2 flex justify-between border-b border-r border-l  h-8">
//             <span className="text-gray-500">Transportation Charges</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.transportation_charge)}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between border-b border-r border-l   h-8">
//             <span className="text-gray-500">Normal Amount</span>
//             <span className="block font-semibold text-xs">
//             ₹{(currency(leaseSpecificDetails?.normal_amount))}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between border-b border-r border-l   h-8">
//             <span className="text-gray-500"> Overtime Amount</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.overtime_amount)}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between border-b border-r border-l   h-8">
//             <span className="text-gray-500">  Reimbursements</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.reimbursements)}
//             </span>
//           </div>
//           <div className="p-2 flex justify-between border-b border-r border-l  round-b-lg h-8">
//             <span className="text-gray-500">  Total Claimable Amount</span>
//             <span className="block font-semibold text-xs">
//             ₹{currency(leaseSpecificDetails?.total_claimable_amount)}
//             </span>
//           </div> */}
//         </div>
//         </div>

   
     
//       </div> */}